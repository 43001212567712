import { View } from "./view";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import {
  isLoadingAction,
  isDoneAction,
} from "../../Redux/actions/utilityActions";
import { tokenSelector, isDoneSelector } from "../../Redux/selector/";
import { saveNewPassAction } from "../../Redux/actions/forgotPasswordAction";

export const ChangePassword = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => tokenSelector(state));
  const codeRef = useRef(null);
  const [codeError, setCodeError] = useState(false);
  const passwordRef = useRef(null);
  const [passwordError, setPasswordError] = useState(false);
  const passwordConfirmRef = useRef(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const isDone = useSelector((state) => isDoneSelector(state));
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  const inputFields = {
    email: {
      type: "text",
      label: "Verification code",
      ref: codeRef,
      error: codeError,
      placeholder: "",
      inputProps: {
        shrink: true,
      },
    },
    password: {
      label: "New Password",
      ref: passwordRef,
      error: passwordError,
      placeholder: "New Password",
      type: showPassword ? "text" : "password",
      inputProps: {
        shrink: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
    confirmPassword: {
      label: "Confirm Password",
      ref: passwordConfirmRef,
      error: passwordConfirmError,
      placeholder: "Confirm Password",
      type: showPasswordConfirm ? "text" : "password",
      inputProps: {
        shrink: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPasswordConfirm}
              onMouseDown={handleMouseDownPasswordConfirm}
            >
              {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
  };

  useEffect(() => {
    dispatch(isDoneAction(false));
    const email = state?.email;
    if (!email) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]);

  useEffect(() => {
    if (isDone) {
      dispatch(isDoneAction(false));
      navigate("/");
    }
  }, [isDone]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const code = codeRef?.current?.value || setCodeError(true);
    const password = passwordRef?.current?.value || setPasswordError(true);
    const confirmPassword =
      passwordConfirmRef?.current?.value || setPasswordConfirmError(true);

    /** if it has value, false the error */
    code && setCodeError(false);

    if (password === confirmPassword) {
      setPasswordError(false);
      setPasswordConfirmError(false);
    }
    if (password !== confirmPassword || !password || !confirmPassword) {
      setPasswordError(true);
      setPasswordConfirmError(true);
    }
    /** stop executing  */
    if (!code || !password || !confirmPassword || password !== confirmPassword)
      return;

    dispatch(isLoadingAction(true));
    dispatch(
      saveNewPassAction({
        email: state?.email,
        organization: "TrackDetailing",
        token_recovery_password: code,
        new_password: password,
      })
    );
  };
  return <View handleSubmit={handleSubmit} inputFields={inputFields} />;
};
