import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../../utils/theme";
import { Button, Input, Modal } from "../../../../components/";
import { createUserAction } from "../../../../Redux/actions/userListActions";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
// Styling

export const CreateUser = ({
  isModal,
  handleClose,
  isLoading,
  isLoadingAction,
}) => {
  const dispatch = useDispatch();

  const [selectValue, setSelectValue] = useState("Admin");
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const [firstError, setFirstError] = useState(false);
  const [lastError, setLastError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cPasswordError, setCPasswordError] = useState(false);
  const [selectError, setSelectError] = useState(false);
  const setSelectValueHandler = (e) => {
    setSelectValue(e.target.value);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputFields = {
    firstName: {
      elementType: "input",
      placeholder: "First name",
      ref: firstNameRef,
      error: firstError,
    },
    lastName: {
      elementType: "input",
      placeholder: "Last name",
      ref: lastNameRef,
      error: lastError,
    },
    email: {
      elementType: "email",
      placeholder: "E-mail",
      ref: emailRef,
      error: emailError,
    },
    phone: {
      elementType: "phone",
      placeholder: "Phone Number",
      ref: phoneRef,
      error: phoneError,
    },
    password: {
      elementType: showPassword ? "input" : "password",
      placeholder: "Password",
      ref: passwordRef,
      error: passwordError,
      inputProps: {
        shrink: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
    confirmPassword: {
      elementType: showPasswordConfirm ? "input" : "password",
      placeholder: "Confirm Password",
      ref: confirmPasswordRef,
      error: cPasswordError,
      inputProps: {
        shrink: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPasswordConfirm}
              onMouseDown={handleMouseDownPassword}
            >
              {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
    userType: {
      label: "User Type",
      elementType: "select",
      value: selectValue,
      list: ["Admin", "Manager", "Employee"],
      error: selectError,
      handler: setSelectValueHandler,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const first = firstNameRef?.current?.value || setFirstError(true);
    const last = lastNameRef?.current?.value || setLastError(true);
    const email = emailRef?.current?.value || setEmailError(true);
    const phone = phoneRef?.current?.value || setPhoneError(true);
    const password = passwordRef?.current?.value || setPasswordError(true);
    const confirmPassword =
      confirmPasswordRef?.current?.value || setCPasswordError(true);
    selectValue || setSelectError(true);

    if (password !== confirmPassword) {
      setPasswordError(true);
      setCPasswordError(true);
    }

    /** if it has value, false the error */
    first && setFirstError(false);
    last && setLastError(false);
    email && setEmailError(false);
    phone && setPhoneError(false);
    selectValue && setSelectError(false);

    if (password === confirmPassword) {
      setPasswordError(false);
      setCPasswordError(false);
    }

    /** stop executing  */
    if (
      !first ||
      !last ||
      !email ||
      !phone ||
      !password ||
      !confirmPassword ||
      !selectValue ||
      password !== confirmPassword
    )
      return;

    dispatch(isLoadingAction(true));

    dispatch(
      createUserAction({
        first_name: first,
        last_name: last,
        email,
        phone_number: phone,
        organization: "TrackDetailing",
        password,
        type: selectValue,
        status: "Pending",
      })
    );
  };

  return (
    <Modal open={isModal} handleClose={handleClose} borderRadius={10}>
      <Box sx={{ padding: "20px 20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: 600 }} variant="h6" gutterBottom>
            Add New User
          </Typography>
          <CloseIcon
            sx={{
              backgroundColor: theme.palette.buttons.main,
              borderRadius: 20,
              color: theme.palette.common.white,
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Box>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container>
            {Object.keys(inputFields).map((key) => (
              <Grid item xs={6} key={key}>
                <Input fields={inputFields[key]} selectWidth={"550px"} />
              </Grid>
            ))}
          </Grid>

          <Button
            colorButton="buttons"
            title="Add"
            width="100%"
            marginBottom="0"
            marginTop="30px"
            isLoading={isLoading}
          />
        </form>
      </Box>
    </Modal>
  );
};

CreateUser.prototype = {
  isModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingAction: PropTypes.func.isRequired,
};
