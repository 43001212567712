/** Utility  */
export const SNACBAR = "SNACKBAR";
export const IS_LOADING = "IS_LOADING";
export const IS_DONE = "IS_DONE";
export const IS_MODAL_CREATE_USER = "IS_MODAL_CREATE_USER";
export const IS_MODAL_EDIT_USER = "IS_MODAL_EDIT_USER";
export const IS_MODAL_SEND_INVOICE = "IS_MODAL_SEND_INVOICE";
export const IS_LOADING_MODAL = "IS_LOADING_MODAL";
/** Login */
export const LOGIN_START = "LOGIN_START";
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
export const ASSESS_TOKEN = "ASSESS_TOKEN";
export const EMAIL = "EMAIL";
export const IS_RESET_TOKEN_VALID = "IS_RESET_TOKEN_VALID";
export const IS_RESET_TOKEN_LOADING = "IS_RESET_TOKEN_LOADING";

/** Logout  */
export const LOGOUT_START = "LOG_OUT";
export const LOGOUT_COMPLETE = "LOG_OUT";

/** User Info  */
export const USER_INFO_START = "USER_INFO_START";
export const USER_INFO_COMPLETE = "USER_INFO_COMPLETE";
export const RESET_INFO_USER = "RESET_INFO_USER";
/** Dashboard */
export const DASHBOARD_DATA_START = "DASHBOARD_DATA_START";
export const DASHBOARD_DATA_COMPLETE = "DASHBOARD_DATA_COMPLETE";

/** Invoice  */
export const GET_INVOICES_START = "GET_INVOICES_START";
export const GET_INVOICES_COMPLETE = "GET_INVOICES_COMPLETE";
export const GET_INVOICE_BY_DEALER_AND_STATUS_START =
  "GET_INVOICE_BY_DEALER_AND_STATUS_START";
export const GET_INVOICE_BY_DEALER_AND_STATUS_COMPLETE =
  "GET_INVOICE_BY_DEALER_AND_STATUS_COMPLETE";
export const INVOICE_IS_LOADING = "INVOICE_IS_LOADING";

export const GET_INVOICES_PENDING_BY_DEALER_START =
  "GET_INVOICE_PENDING_BY_DEALER_START";
export const GET_INVOICES_PENDING_BY_DEALER_COMPLETE =
  "GET_INVOICE_PENDING_BY_DEALER_COMPLETE";

export const RESET_INVOICES_LIST = "RESET_INVOICES_LIST";

export const PAYING_INVOICE_BY_NUMBER_START = "PAYING_INVOICE_BY_NUMBER_START";
export const PAYING_INVOICE_BY_NUMBER_COMPLETE =
  "PAYING_INVOICE_BY_NUMBER_COMPLETE";

export const GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_START =
  "GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_START";
export const GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_COMPLETE =
  "GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_COMPLETE";
export const GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_START =
  "GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_START";
export const GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_COMPLETE =
  "GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_COMPLETE";

export const SET_INVOICE_NUMBER_DASHBOARD_START =
  "SET_INVOICE_NUMBER_DASHBOARD_START";
export const SET_INVOICE_NUMBER_DASHBOARD_COMPLETE =
  "SET_INVOICE_NUMBER_DASHBOARD_COMPLETE";

/** Tasks */
export const GET_TASKS_START = "GET_TASKS_START";
export const GET_TASKS_COMPLETE = "GET_TASKS_COMPLETE";
export const SELECTED_TASK_START = "SELECTED_TASK_START";
export const SELECTED_TASK_COMPLETE = "SELECTED_TASK_COMPLETE";
export const CREATE_TASK_START = "CREATE_TASK_START";
export const CREATE_TASK_COMPLETE = "CREATE_TASK_COMPLETE";
export const SHOW_TASK_MODAL_COMPLETE = "SHOW_TASK_MODAL_COMPLETE";
export const EDIT_TASK_START = "EDIT_TASK_START";
export const EDIT_TASK_COMPLETE = "EDIT_TASK_COMPLETE";
export const ADD_SELECTED_TASK = "ADD_SELECTED_TASK";
export const DELETE_SELECTED_TASK = "DELETE_SELECTED_TASK";
export const TASK_FOR_DROPDOWN_START = "TASK_FOR_DROPDOWN_START";
export const TASK_FOR_DROPDOWN_COMPLETE = "TASK_FOR_DROPDOWN_COMPLETE";
export const TASK_IS_LOADING = "TASK_IS_LOADING";

/** Search */
export const SEARCH_QUERY_START = "SEARCH_QUERY_START";

/**DEALERS */
export const GET_DEALERS_START = "GET_DEALERS_START";
export const GET_DEALERS_COMPLETE = "GET_DEALERS_COMPLETE";
export const DEALER_IS_LOADING = "DEALER_IS_LOADING";

/** USER LIST */
export const USER_LIST_START = "USER_LIST_START";
export const USER_LIST_COMPLETE = "USER_LIST_COMPLETE";
export const USER_EDIT_START = "USER_EDIT_START";
export const USER_EDIT_COMPLETE = "USER_EDIT_COMPLETE";
export const CREATE_NEW_USER_START = "CREATE_NEW_USER_START";
export const CREATE_NEW_USER_COMPLETE = "CREATE_NEW_USER_COMPLETE";
export const EDIT_USER_START = "EDIT_USER_START";
export const EDIT_USER_COMPLETE = "EDIT_USER_COMPLETE";

/** Forgot password */
export const FORGOT_PASS_START = "FORGOT_PASS_START";
export const FORGOT_PASS_SAVE = "FORGOT_PASS_SAVE";
export const SHOW_FORGOT_MODAL = "SHOW_FORGOT_MODAL";
