import React from "react";

import clsx from "clsx";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
// Styling

export const SelectDropDown = ({
  options,
  selectedItem,
  handleOnChange,
  label,
  width,
  value,
}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 50 }}>
      <InputLabel sx={{ color: "red" }} id="demo-select">
        {label}
      </InputLabel>
      <Select
        labelId={selectedItem}
        id={selectedItem}
        value={value || ""}
        onChange={handleOnChange}
        label={label}
        labelstyle={{ color: "#FD0000" }}
        sx={{
          width: width || "200px",
          borderRadius: "5px",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: "red",
                color: "white",
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: "red",
                color: "white",
              },
              margin: "5px 0px",
            },
          },
        }}
      >
        {options?.map((item) => (
          <MenuItem
            sx={{ borderRadius: "5px" }}
            key={item?.value}
            value={item?.value}
          >
            {item?.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectDropDown.propTypes = {
  handleOnChange: PropTypes.func,
  data: PropTypes.array,
  selectedItem: PropTypes.string,
  minWidth: PropTypes.string,
  width: PropTypes.string,
  colors: PropTypes.object,
  label: PropTypes.string,
};
