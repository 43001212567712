import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { theme } from "../../../../utils/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { downloadInvoice } from "../../../../utils/generaluFunctions";
import DownloadIcon from "@mui/icons-material/Download";

function Row(props) {
  const { invoice_number, invoices, handleModalPayInvoiceOpen } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {" "}
          <Typography fontWeight="bold">Invoice Number</Typography>
        </TableCell>
        <TableCell align="left">{invoice_number}</TableCell>
        <TableCell align="right">
          <Button
            onClick={() => downloadInvoice(invoices, false, false)}
            sx={{ marginRight: "20px" }}
            variant="contained"
            color="error"
          >
            <DownloadIcon />
          </Button>
          <Button
            onClick={() =>
              handleModalPayInvoiceOpen({
                invoiceNumber: invoice_number,
              })
            }
            sx={{ width: "120px" }}
            variant="contained"
            color="warning"
          >
            PAY
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ paddingBottom: "20px", paddingTop: "5px" }}>
              <Table size="small" aria-label="purchases">
                <TableHead
                  sx={{
                    "& th": {
                      color: theme.palette.white,
                      backgroundColor: theme.palette.primary.main,
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Car</TableCell>
                    <TableCell>Stock Number</TableCell>
                    <TableCell>VIN No</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell align="center">Invoice No</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: theme.palette.bgColor }}>
                  {invoices?.map((invoice, index) =>
                    invoice?.vehicles?.map((vehicle, idx) => {
                      return (
                        <TableRow key={vehicle?.vin}>
                          <TableCell>{invoice.number}</TableCell>
                          <TableCell>
                            {vehicle?.make + " " + vehicle?.model ||
                              "NOT FOUND"}
                          </TableCell>
                          <TableCell>{vehicle?.stock || "NOT FOUND"}</TableCell>
                          <TableCell>{vehicle?.vin || "NOT FOUND"}</TableCell>
                          <TableCell>
                            {vehicle?.description || "NOT FOUND"}
                          </TableCell>
                          <TableCell>
                            {vehicle?.total
                              ? String(vehicle?.total).charAt(0) === "$"
                                ? "$" +
                                  parseFloat(vehicle?.total.slice(1)).toFixed(2)
                                : "$" + parseFloat(vehicle?.total).toFixed(2)
                              : "$0.00"}
                          </TableCell>
                          <TableCell align="center">
                            {invoice_number || "NOT FOUND"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {};

export const CollapsibleTable = ({
  invoicesList,
  isLoading,
  handleOnChange,
  handleModalPayInvoiceOpen,
}) => {
  const [modalTasks, setModalTasks] = useState([]);

  if (isLoading) return false;

  const handleModalOpen = (tasks) => {
    if (!tasks) return;
    setModalTasks(tasks);
  };

  const handleModalClose = () => {
    setModalTasks([]);
  };

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {invoicesList?.map((invoice) => (
              <Row
                key={invoice.invoice_number}
                {...invoice}
                handleModalPayInvoiceOpen={handleModalPayInvoiceOpen}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

CollapsibleTable.propTypes = {
  invoicesList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
