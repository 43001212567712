import React from "react";
import clsx from "clsx";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
const PREFIX = "MyDrawer";
const classes = {
  select: `${PREFIX}-select`,
  selectControl: `${PREFIX}-selectControl`,
  green: `${PREFIX}-green`,
  red: `${PREFIX}-red`,
  yellow: `${PREFIX}-yellow`,
  normal: `${PREFIX}-normal`,
  arrowDownIcon: `${PREFIX}-arrowDownIcon`,
};

const Root = styled(Select)(({ theme }) => ({
  [`&.${classes.select}`]: {
    padding: "4px ",
    paddingRight: "0 !important",
  },
  [`&.${classes.selectControl}`]: {
    minWidth: ({ minWidth }) => minWidth || "95px",
    borderRadius: 20,
    textAlign: "center",
    fontWeight: 600,
  },
  [`&.${classes.green}`]: {
    background: theme.palette.active.secondary,
    color: theme.palette.active.main,
  },
  [`&.${classes.red}`]: {
    background: theme.palette.rejected.secondary,
    color: theme.palette.rejected.main,
  },
  [`&.${classes.yellow}`]: {
    background: theme.palette.pending.secondary,
    color: theme.palette.pending.main,
  },
  [`&.${classes.normal}`]: { background: theme.palette.gray + "!important" },
  [`&.${classes.arrowDownIcon}`]: { display: "none" },
}));

// Styling

export const SelectCustomer = ({
  data,
  selectedItem,
  colors,
  minWidth,
  handleOnChange,
  width,
  height,
}) => {
  return (
    <FormControl sx={{ width: width || "140px" }}>
      <Root
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          height: height || "35px",
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedItem}
        onChange={handleOnChange}
        className={clsx(classes.selectControl, {
          [classes.green]: selectedItem === colors.green,
          [classes.red]: selectedItem === colors.red,
          [classes.yellow]: selectedItem === colors.yellow,
          [classes.normal]: selectedItem === colors.normal,
        })}
        classes={{
          select: classes.select,
          icon: classes.arrowDownIcon,
        }}
        disableUnderline
      >
        {data?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Root>
    </FormControl>
  );
};

SelectCustomer.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  selectedItem: PropTypes.string.isRequired,
  minWidth: PropTypes.string,
  colors: PropTypes.object.isRequired,
};
