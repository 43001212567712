import { styled } from "@mui/material/";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import DateFnsUtils from "@date-io/dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button, DatePicker, Select } from "../../../../components";
import { theme } from "../../../../utils/theme";
const PREFIX = "MySearchBar";
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
};
const Root = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  [`&.${classes.paper}`]: {
    padding: "11px",
  },
}));

export const SearchBar = ({
  selectFields,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  return (
    <Root classes={{ root: classes.paper }}>
      <div>
        <form className={classes.root} noValidate autoComplete="off">
          {Object.keys(selectFields).map((key) => (
            <Select
              key={key}
              id={key}
              label={selectFields[key].label}
              name={selectFields[key].name}
              options={selectFields[key].options}
              handleOnChange={(event) => {
                selectFields[key].setCustomObjectData(event.target.value);
              }}
              value={selectFields[key].value}
              width={selectFields[key].width}
            />
          ))}
          <DatePicker
            value={startDate}
            setValue={setStartDate}
            label={"Start Date"}
          ></DatePicker>
          <DatePicker
            value={endDate}
            label={"End Date"}
            setValue={setEndDate}
          ></DatePicker>
        </form>
      </div>
    </Root>
  );
};

SearchBar.propTypes = {
  isLoading: PropTypes.bool,
  btnTitle: PropTypes.string,
};
