/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  View,
  Text,
} from "@react-pdf/renderer";
import InvoiceItemsTable from "./InvoiceItemsTable";
import logo from "../../../../assets/images/allstar.png";
import logoApp from "../../../../assets/images/logo_app_pdf_report.png";
import dayjs from "dayjs";
import { Font } from "@react-pdf/renderer";
import InterBoldfrom from "../../../../assets/fonts/Inter-Bold.ttf";
Font.register({
  family: "interBold",
  fonts: [
    {
      src: InterBoldfrom,
    },
    {
      src: InterBoldfrom,
      fontWeight: "bold",
    },
    {
      src: InterBoldfrom,
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      src: InterBoldfrom,
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
  format: "truetype",
});
const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 20,
    paddingBottom: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 130,
    height: 50,
  },
  logoCompany: {
    width: 150,
    height: 100,
  },
  headerContainer: {
    flexDirection: "row",
    marginTop: 0,
    margin: 0 /* no uses el = aquí, usa los : */,
    padding: 0,
  },
  headerImage: {
    flexDirection: "column",
    alignItems: "flex-start",

    paddingTop: "0",
  },
  headerInvoice: {
    flexDirection: "column",
    alignItems: "flex-end",
    alignContent: "start",
    paddingLeft: "400px",
  },
  labelHeader: {
    flexDirection: "row",
    width: "300px",
    fontSize: 16,
    fontStyle: "bold",
    paddingLeft: "200px",
  },
  labelDirectionAllStart: {
    width: "300px",
    fontSize: 10,
    fontStyle: "bold",
  },
  headerDirectionDealer: {
    flexDirection: "column",

    width: "400px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "black",
    paddingTop: "50%",
  },
});

const stylesTableDateHeader = StyleSheet.create({
  table: {
    width: "150px",
  },
  rowTittleTable: {
    display: "flex",
    flexDirection: "row",
    borderTop: 1,
    borderTopColor: "#000000",

    alignItems: "center",
    borderBottom: 1,
    borderBottomColor: "#000000",
  },
  row: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
  },
  header: {
    borderTop: 1,
    borderTopColor: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  rowHeader1: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "12",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,
  },
  rowHeader2: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "12",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
  },

  row1: {
    paddingTop: "1%",
    paddingLeft: "1%",
    fontSize: "10",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,
    borderBottom: 1,
    textAlign: "center",
    alignItems: "center",
    height: "100%",
  },
  row2: {
    paddingTop: "1%",
    fontSize: "10",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    textAlign: "center",
    borderBottom: 1,
    alignItems: "center",
  },
});

const stylesTableBillTo = StyleSheet.create({
  table: {
    paddingTop: "15px",
    width: "257px",
  },
  rowTittleTable: {
    display: "flex",
    flexDirection: "row",
    borderTop: 1,
    borderTopColor: "#000000",

    alignItems: "center",
    borderBottom: 1,
    borderBottomColor: "#000000",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    borderTop: 1,
    borderTopColor: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  rowHeader1: {
    paddingTop: "1%",
    fontWeight: 800,
    textAlign: "center",
    fontSize: "12",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,
  },

  row1: {
    paddingTop: "1%",
    paddingLeft: "2%",
    fontSize: "10",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,
    borderBottom: 1,
    height: "60px",
  },
});

const stylesTableDateApproved = StyleSheet.create({
  table: {
    width: "257.7px",
    paddingTop: "70px",
  },
  rowTittleTable: {
    display: "flex",
    flexDirection: "row",
    borderTop: 1,
    borderTopColor: "#000000",

    alignItems: "center",
    borderBottom: 1,
    borderBottomColor: "#000000",
  },
  row: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
  },
  header: {
    borderTop: 1,
    borderTopColor: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  rowHeader1: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "12",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,
  },
  rowHeader2: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "12",
    width: "26.2%",
    borderBottomColor: "#000000",
    borderRight: 1,
  },

  row1: {
    paddingTop: "1%",
    paddingLeft: "1%",
    fontSize: "10",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,

    textAlign: "center",
    alignItems: "center",
    height: "100%",
  },
  row2: {
    paddingTop: "1%",
    fontSize: "10",
    width: "26.2%",
    borderBottomColor: "#000000",
    borderRight: 1,
    textAlign: "center",

    alignItems: "center",
  },
});

const stylesFooter = StyleSheet.create({
  logoApp: {
    paddingTop: "15px",
    width: "160px",
    height: "50px",
  },
  headerContainer: {
    flexDirection: "row",
    marginTop: 0,
    margin: 0 /* no uses el = aquí, usa los : */,
    padding: 0,
  },
  headerImage: {
    flexDirection: "column",
    alignItems: "flex-start",

    paddingTop: "0",
  },
  headerInvoice: {
    flexDirection: "column",
    alignItems: "flex-end",
    alignContent: "start",
    paddingLeft: "400px",
  },
  labelHeader: {
    flexDirection: "row",
    width: "300px",
    fontSize: 16,
    fontStyle: "bold",
    paddingLeft: "200px",
  },
  labelDirectionAllStart: {
    width: "300px",
    fontSize: 10,
    fontStyle: "bold",
  },
  headerDirectionDealer: {
    flexDirection: "column",

    width: "400px",
  },
});

const stylesTableTotal = StyleSheet.create({
  table: {
    width: "150px",
  },
  rowTittleTable: {
    flexDirection: "row",
    borderTopColor: "#000000",
    borderBottom: 1,
    borderBottomColor: "#000000",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  header: {
    borderTopColor: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  rowHeader1: {
    paddingTop: "1%",
    paddingLeft: "5%",
    textAlign: "left",
    fontSize: "12",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,
  },

  row1: {
    paddingTop: "1%",
    paddingLeft: "5%",
    fontSize: "12",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    borderLeft: 1,
    borderBottom: 1,
    fontWeight: 800,
    alignItems: "center",
  },
  rowleft: {
    paddingLeft: "5%",
    fontWeight: 800,
    fontSize: "12",
    width: "100%",
    borderLeft: 1,
    textAlign: "left",
  },
  rowright: {
    paddingRight: "2%",
    fontWeight: 800,
    fontSize: "12",
    width: "100%",
    borderBottomColor: "#000000",
    borderRight: 1,
    textAlign: "right",
  },
});

const Invoice = ({ invoice, invoices, imgCompany, isPaid = false }) => {
  let total = 0;
  let subtotal = 0;
  invoices?.map((itm, idx) => {
    total = Number(total) + Number(itm?.total);
    subtotal = Number(subtotal) + Number(itm?.sub_total);
  });
  return (
    <Document>
      <Page size="A4" wrap style={styles.page}>
        {isPaid && (
          <Image
            src={require("../../../../assets/images/paidstamp.png")}
            style={{
              position: "absolute",
              zIndex: 10,
              width: "300px",
              marginLeft: "160px",
              marginTop: "350px",
            }}
          />
        )}
        <View style={styles.headerContainer}>
          <View style={styles.headerImage}>
            <Image style={styles.logo} src={logo} />
          </View>
          <View style={styles.headerInvoice}>
            <Text style={styles.labelHeader}>Invoice</Text>
            <View style={stylesTableDateHeader.table}>
              <View
                style={[
                  stylesTableDateHeader.rowTittleTable,
                  stylesTableDateHeader.bold,
                  stylesTableDateHeader.header,
                ]}
              >
                <Text style={stylesTableDateHeader.rowHeader1}>Date</Text>
                <Text style={stylesTableDateHeader.rowHeader2}>Invoice #</Text>
              </View>

              <View style={stylesTableDateHeader.row} wrap={false}>
                <Text style={stylesTableDateHeader.row1}>
                  {invoice?.dateInvoice ||
                    dayjs(invoice?.created_invoice_general).format(
                      "MM/DD/YYYY"
                    )}
                </Text>
                <Text style={stylesTableDateHeader.row2}>
                  {invoice?.invoiceNumber ||
                    invoice?.invoice_number ||
                    invoice?.invoice_general_number}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerContainer}>
          <View style={styles.headerDirectionDealer}>
            <Text style={styles.labelDirectionAllStart}>
              AllStar Detailing LLC
            </Text>
            <Text style={styles.labelDirectionAllStart}>
              11402 Nw 41 st Street
            </Text>
            <Text style={styles.labelDirectionAllStart}>Suite 202</Text>
            <Text style={styles.labelDirectionAllStart}>Doral,FI 13178</Text>
          </View>
          <View style={styles.headerInvoice}>
            <View style={stylesTableBillTo.table}>
              <View
                style={[
                  stylesTableBillTo.rowTittleTable,
                  stylesTableBillTo.bold,
                  stylesTableBillTo.header,
                ]}
              >
                <Text style={stylesTableBillTo.rowHeader1}>Bill To</Text>
              </View>

              <View style={stylesTableBillTo.row} wrap={false}>
                <View style={stylesTableBillTo.row1}>
                  <Text style={{ fontFamily: "interBold" }}>
                    {`${invoice?.dealer?.name}\n` || `${invoice?.nameDealer}\n`}
                  </Text>
                  <Text>{invoice?.address || invoice?.dealer?.address}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerContainer}>
          <Image style={styles.logoCompany} src={imgCompany} />
          <View style={styles.headerInvoice}>
            <View style={stylesTableDateApproved.table}>
              <View
                style={[
                  stylesTableDateApproved.rowTittleTable,
                  stylesTableDateApproved.bold,
                  stylesTableDateApproved.header,
                ]}
              >
                <Text style={stylesTableDateApproved.rowHeader1}>
                  Approved By
                </Text>
                <Text style={stylesTableDateApproved.rowHeader2}>Serviced</Text>
              </View>

              <View style={stylesTableDateApproved.row} wrap={false}>
                <Text style={stylesTableDateApproved.row1}>
                  {invoice?.nameManager ||
                    invoice?.user_manager?.first_name +
                      " " +
                      invoice?.user_manager?.last_name}
                </Text>
                <Text style={stylesTableDateApproved.row2}>
                  {invoice?.dateInvoice ||
                    dayjs(invoice?.updated_at).format("MM/DD/YYYY")}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <InvoiceItemsTable invoice={invoice} invoices={invoices} />
        <View style={stylesFooter.headerContainer}>
          <Image style={stylesFooter.logoApp} src={logoApp} />
          <View style={stylesFooter.headerInvoice}>
            <View style={stylesTableTotal.table}>
              {invoices?.length > 0 ? (
                <View style={stylesTableTotal.rowTittleTable}>
                  <Text style={stylesTableTotal.rowleft}>SubTotal</Text>
                  <Text style={stylesTableTotal.rowright}>
                    ${subtotal?.toFixed(2)?.toString()}
                  </Text>
                </View>
              ) : (
                <View style={stylesTableTotal.rowTittleTable}>
                  <Text style={stylesTableTotal.rowleft}>SubTotal</Text>
                  <Text style={stylesTableTotal.rowright}>
                    {invoice?.totalInvoice
                      ? String(invoice?.totalInvoice).charAt(0) === "$"
                        ? "$" +
                          parseFloat(invoice?.totalInvoice.slice(1)).toFixed(2)
                        : "$" + parseFloat(invoice?.totalInvoice).toFixed(2)
                      : "$0.00"}
                  </Text>
                </View>
              )}

              <View style={stylesTableTotal.rowTittleTable}>
                <Text style={stylesTableTotal.rowleft}>Tax (0%)</Text>
                <Text style={stylesTableTotal.rowright}>$0.00</Text>
              </View>

              {invoices?.length > 0 ? (
                <View style={stylesTableTotal.rowTittleTable}>
                  <Text style={stylesTableTotal.rowleft}>Total</Text>
                  <Text style={stylesTableTotal.rowright}>
                    ${total.toFixed(2)}
                  </Text>
                </View>
              ) : (
                <View style={stylesTableTotal.rowTittleTable}>
                  <Text style={stylesTableTotal.rowleft}>Total</Text>
                  <Text style={stylesTableTotal.rowright}>
                    {invoice?.totalInvoice
                      ? String(invoice?.totalInvoice).charAt(0) === "$"
                        ? "$" +
                          parseFloat(invoice?.totalInvoice.slice(1)).toFixed(2)
                        : "$" + parseFloat(invoice?.totalInvoice).toFixed(2)
                      : "$0.00"}
                  </Text>
                </View>
              )}
              <View style={stylesTableTotal.row} wrap={false}></View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default Invoice;
