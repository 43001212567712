import { put, call, takeLatest } from "redux-saga/effects";

import { FORGOT_PASS_START, FORGOT_PASS_SAVE, IS_DONE } from "../actionType/";
import { snackbarAction, isLoadingAction } from "../actions/utilityActions";

import { FORGOT_PASS, SAVE_PASS } from "../../utils/urls";
import { apiCall } from "../../utils/apiCall";

function* forgotPassword({ data }) {
  try {
    const result = yield call(apiCall, FORGOT_PASS, data, null, "POST");
    yield put(
      snackbarAction({
        open: true,
        type: "success",
        message: result.data.message,
      })
    );
    yield put({ type: IS_DONE, isDone: true });
  } catch (error) {
    yield put({ type: IS_DONE, isDone: false });
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error?.response?.data.message || "Internal Error",
      })
    );
  }
  yield put(isLoadingAction(false));
}

function* saveNewPassword({ data }) {
  try {
    const result = yield call(apiCall, SAVE_PASS, data, null, "POST");
    yield put(
      snackbarAction({
        open: true,
        type: "success",
        message: result.data.message,
      })
    );
    yield put({ type: IS_DONE, isDone: true });
  } catch (error) {
    yield put({ type: IS_DONE, isDone: false });
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error?.response?.data.message || "Internal Error",
      })
    );
  }
  yield put(isLoadingAction(false));
}

export default function* watchForgetPassword() {
  yield takeLatest(FORGOT_PASS_START, forgotPassword);
  yield takeLatest(FORGOT_PASS_SAVE, saveNewPassword);
}
