import { get } from "lodash";
import {
  SNACBAR,
  IS_LOADING,
  IS_MODAL_CREATE_USER,
  IS_MODAL_EDIT_USER,
  IS_MODAL_SEND_INVOICE,
  IS_LOADING_MODAL,
  IS_DONE,
} from "../actionType";

const initialState = {
  isDone: false,
  isLoading: false,
  isLoadingModal: false,
  isModalCreateUser: false,
  isModalEditUser: false,
  isModalSendInvoice: false,
  snackBar: {
    open: false,
    type: "",
    message: "",
  },
};

export const utilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACBAR:
      return {
        ...state,
        snackBar: get(action, "snackBar"),
      };

    case IS_LOADING:
      return { ...state, isLoading: get(action, "isLoading") };
    case IS_DONE:
      return { ...state, isDone: get(action, "isDone") };
    case IS_LOADING_MODAL:
      return { ...state, isLoadingModal: get(action, "isLoadingModal") };
    case IS_MODAL_CREATE_USER:
      return { ...state, isModalCreateUser: get(action, "isModal") };
    case IS_MODAL_EDIT_USER:
      return { ...state, isModalEditUser: get(action, "isModal") };
    case IS_MODAL_SEND_INVOICE:
      return { ...state, isModalSendInvoice: get(action, "isModal") };
    default:
      return state;
  }
};
