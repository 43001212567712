export const URL_API = "https://trackdetailingapi.triallies.com/";
export const AUTH = "auth";
export const GET_USER_INFO = "protected";
export const TASK_SEARCH = "task_search";
export const TASK = "task";
export const DEALER = "dealer";
export const DEALER_BY_VENDOR = "dealerbyvendor";
export const INVOICE_DASBOARD_LIST = "invoice_dashboard_list";
export const INVOICE_PENDING_LIST = "invoice_pending_list";
export const PAYING_INVOICE_BY_NUMBER = "paying_invoice_by_number";
export const INVOICE_PAID_LIST = "invoice_paid_list";
export const GENERATE_INVOICE_DASHBOARD = "generate_invoice_dashboard";
export const USERLIST = "user";
export const USERS = "users";
export const USER_SEARCH = "user_search";
export const FORGOT_PASS = "forgot_password";
export const SAVE_PASS = "recovery_change_password";
