import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../../utils/theme";
import { Button, Input, Modal } from "../../../../components/";

import { editUserAction } from "../../../../Redux/actions/userListActions";

// Styling

export const EditUser = ({
  isModal,
  handleClose,
  isLoading,
  isLoadingAction,
  defaultUser,
}) => {
  const dispatch = useDispatch();

  const [selectValue, setSelectValue] = useState(
    defaultUser?.type === "Super Admin"
      ? "Admin"
      : defaultUser?.type === "Admin"
      ? "Manager"
      : "Employee"
  );
  const [userStatus, setUserStatus] = useState(defaultUser?.status);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    setSelectValue(
      defaultUser?.type === "Super Admin"
        ? "Admin"
        : defaultUser?.type === "Admin"
        ? "Manager"
        : "Employee"
    );
    setUserStatus(defaultUser?.status);
  }, [defaultUser]);

  const [firstError, setFirstError] = useState(false);
  const [lastError, setLastError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectError, setSelectError] = useState(false);
  const [userStatusError, setUserStatusError] = useState(false);

  const setSelectValueHandler = (e) => {
    setSelectValue(e.target.value);
  };

  const setUserStatusHandler = (e) => {
    setUserStatus(e.target.value);
  };

  const inputFields = {
    firstName: {
      elementType: "input",
      placeholder: "First name",
      ref: firstNameRef,
      error: firstError,
      defaultValue: defaultUser?.first_name,
    },
    lastName: {
      elementType: "input",
      placeholder: "Last name",
      ref: lastNameRef,
      error: lastError,
      defaultValue: defaultUser?.last_name,
    },
    email: {
      elementType: "email",
      placeholder: "E-mail",
      ref: emailRef,
      error: emailError,
      defaultValue: defaultUser?.email,
      disable: true,
    },
    phone: {
      elementType: "phone",
      placeholder: "Phone Number",
      ref: phoneRef,
      error: phoneError,
      defaultValue: defaultUser?.phone_number,
    },
    userType: {
      label: "User Type",
      elementType: "select",
      list: ["Admin", "Manager", "Employee"],
      error: selectError,
      value: selectValue,
      handler: setSelectValueHandler,
    },
    userState: {
      label: "User Status",
      elementType: "select",
      list: ["Active", "Pending", "Rejected", "Deactivated"],
      error: userStatusError,
      value: userStatus,
      handler: setUserStatusHandler,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const first = firstNameRef?.current?.value || setFirstError(true);
    const last = lastNameRef?.current?.value || setLastError(true);
    const email = emailRef?.current?.value || setEmailError(true);
    const phone = phoneRef?.current?.value || setPhoneError(true);

    selectValue || setSelectError(true);
    userStatus || setUserStatusError(true);

    /** if it has value, false the error */
    first && setFirstError(false);
    last && setLastError(false);
    email && setEmailError(false);
    phone && setPhoneError(false);
    selectValue && setSelectError(false);
    userStatus && setUserStatusError(false);

    /** stop executing  */
    if (!first || !last || !email || !phone || !selectValue || !userStatus)
      return;

    dispatch(isLoadingAction(true));

    dispatch(
      editUserAction({
        _id: defaultUser._id,
        first_name: first,
        last_name: last,
        email,
        phone_number: phone,
        organization: "TrackDetailing",
        type: selectValue,
        status: userStatus,
      })
    );
  };

  return (
    <Modal open={isModal} handleClose={handleClose} borderRadius={10}>
      <Box sx={{ padding: "20px 20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: 600 }} variant="h6" gutterBottom>
            Edit User
          </Typography>
          <CloseIcon
            sx={{
              backgroundColor: theme.palette.buttons.main,
              borderRadius: 20,
              color: theme.palette.common.white,
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Box>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container>
            {Object.keys(inputFields).map((key) => (
              <Grid item xs={6} key={key}>
                <Input fields={inputFields[key]} selectWidth={"270px"} />
              </Grid>
            ))}
          </Grid>

          <Button
            colorButton="buttons"
            title="Save"
            width="100%"
            marginBottom="0"
            marginTop="30px"
            isLoading={isLoading}
          />
        </form>
      </Box>
    </Modal>
  );
};

EditUser.prototype = {
  isModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingAction: PropTypes.func.isRequired,
  defaultUser: PropTypes.object,
};
