import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { View } from "./view";

import { userListAction } from "../../Redux/actions/userListActions";
import {
  isLoadingAction,
  isModalCreateUserAction,
  isModalEditUserAction,
} from "../../Redux/actions/utilityActions";
import {
  userListSelector,
  isLoadingSelector,
  isModalCreateUserSelector,
  isModalEditUserSelector,
} from "../../Redux/selector";
import { searchAction } from "../../Redux/actions/searchAction";
import { USER_SEARCH } from "../../utils/urls";
import { USER_LIST_COMPLETE } from "../../Redux/actionType";

export const Users = () => {
  const dispatch = useDispatch();

  const [userObj, setUserObj] = useState();
  const [searchValue, setSerachValue] = useState("");

  const userList = useSelector((state) => userListSelector(state));
  const isLoading = useSelector((state) => isLoadingSelector(state));
  const isModalCreateUser = useSelector((state) =>
    isModalCreateUserSelector(state)
  );
  const isModalEditUser = useSelector((state) =>
    isModalEditUserSelector(state)
  );

  useEffect(() => {
    if (!userList?.items?.length) {
      dispatch(isLoadingAction(true));
      dispatch(userListAction(1, 10));
    }
  }, [userList]);

  useEffect(() => {
    dispatch(isLoadingAction(true));
    dispatch(userListAction(1, 10));
  }, []);

  const openModalHandler = () => {
    dispatch(isModalCreateUserAction(true));
  };

  const handleCloseCreateUser = () => {
    dispatch(isModalCreateUserAction(false));
  };

  const handleEditUser = (obj) => {
    setUserObj(obj);
    dispatch(isModalEditUserAction(true));
  };

  const handleEditClose = () => {
    dispatch(isModalEditUserAction(false));
  };

  const handlePagination = (event, value) => {
    dispatch(userListAction(value, 10));
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSerachValue(query);
    dispatch(isLoadingAction(true));

    /**
     * Fetch all the user when query is empty
     */
    if (!query) {
      dispatch(userListAction(1, 10));
      return;
    }

    dispatch(searchAction(USER_SEARCH, query, 1, USER_LIST_COMPLETE));
  };

  return (
    <View
      userObj={userObj}
      userList={userList}
      isLoading={isLoading}
      isModalCreateUser={isModalCreateUser}
      isModalEditUser={isModalEditUser}
      openModalHandler={openModalHandler}
      handleCloseCreateUser={handleCloseCreateUser}
      handleEditUser={handleEditUser}
      handleEditClose={handleEditClose}
      handlePagination={handlePagination}
      handleSearch={handleSearch}
      searchValue={searchValue}
    />
  );
};
