import Snackbar from "@mui/material/Snackbar";
import { Alert as MuiAlert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { snackbarSelector } from "../../Redux/selector";
import { snackbarAction } from "../../Redux/actions/utilityActions";

export const SnackbarAlert = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => snackbarSelector(state));

  const closeSnackbar = () => {
    dispatch(
      snackbarAction({
        open: false,
        type: "",
        message: "",
      })
    );
  };

  return (
    <Snackbar
      open={snackbar?.open || false}
      onClose={closeSnackbar}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <MuiAlert
        severity={snackbar?.type || "error"}
        elevation={6}
        variant="filled"
        onClose={closeSnackbar}
      >
        {snackbar?.message || ""}
      </MuiAlert>
    </Snackbar>
  );
};
