import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { theme } from "../../../../utils/theme";
import { Filter, Loading, Pagination } from "../../../../components";
import { NoData } from "../../../../components/NoData/noData";

export const Invoices = ({ invoices, isLoading, handleINVPagination }) => {
  return (
    <Grid item xs={12}>
      <Paper sx={{ padding: "15px 20px" }}>
        <Loading isLoading={isLoading} />
        <NoData data={!!invoices?.length} isLoading={isLoading} />

        {!isLoading && invoices?.length > 0 && (
          <div>
            <TableContainer sx={{ maxHeight: "70vh" }}>
              <Table
                sx={{ marginTop: theme.spacing(2) }}
                stickyHeader
                aria-label="sticky table"
                size="small"
              >
                <TableHead
                  sx={{
                    "& th": {
                      color: theme.palette.white,
                      backgroundColor: theme.palette.primary.main,
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>No </TableCell>
                    <TableCell>Car</TableCell>
                    <TableCell sx={{ minWidth: 130 }}>Stock Number </TableCell>
                    <TableCell>VIN No</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices?.map((invoice, index) =>
                    invoice?.vehicles?.map((car, idx) => {
                      return (
                        <TableRow key={car?.vin}>
                          <TableCell component="th" scope="row">
                            {invoice?.number}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {car?.make + " " + car?.model}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {car?.stock}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {car?.vin}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {car?.description}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {car?.total
                              ? String(car?.total).charAt(0) === "$"
                                ? "$" +
                                  parseFloat(car?.total.slice(1)).toFixed(2)
                                : "$" + parseFloat(car?.total).toFixed(2)
                              : "$0.00"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={invoices?.total_pages}
              onChange={handleINVPagination}
            />
          </div>
        )}
      </Paper>
    </Grid>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleINVPagination: PropTypes.func.isRequired,
};
