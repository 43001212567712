import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/";

export const Modal = ({ open, handleClose, children, borderRadius }) => {
  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: borderRadius || 10 },
      }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  borderRadius: PropTypes.number,
};
