import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1E1E1E",
    },
    secondary: {
      main: "#3A3A3A",
    },
    buttons: {
      main: "#FD0000",
    },
    red: "#FD0000",
    white: "#FFFFFF",
    green: "#1FBE3F",
    bgColor: "#E5EBF1",
    gray: "#E6E6E6",
    active: {
      main: "#3ABF5D",
      secondary: "#D5FFE6",
    },
    rejected: {
      main: "#FF5206",
      secondary: "#FFE0D5",
    },
    pending: {
      main: "#F9BF1F",
      secondary: "#FFFCD5",
    },
  },

  typography: {
    fontFamily: ["Inter-Regular"].join(","),
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
  },
});
