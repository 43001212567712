import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingSelector,
  dealersSelector,
  invoicesSelector,
  invoicesIsLoadingSelector,
} from "../../Redux/selector";
import { View } from "./view";
import { getDealersListAction } from "../../Redux/actions/dealerAction";
import {
  invoicePendingListByDealer,
  resetInvoicesList,
  PayingInvoiceByNumberAction,
} from "../../Redux/actions/invoiceAction";

export const PendingInvoices = () => {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => invoicesSelector(state));
  const isLoading = useSelector((state) => isLoadingSelector(state));
  const dealerList = useSelector((state) => dealersSelector(state));
  const [isModalPayInvoice, setIsModalPayInvoice] = useState(false);
  const invoicesIsLoading = useSelector((state) =>
    invoicesIsLoadingSelector(state)
  );
  const selectDataDealer = dealerList?.items?.map((items) => ({
    value: items?._id,
    text: items?.name,
  }));
  const firstOption = { value: "Select a Dealer", text: "Select a Dealer" };
  selectDataDealer?.unshift(firstOption);
  const [selectFilterDealer, setSelectFilterDealer] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [idInvoice, setIdInvoice] = useState();

  let arrayVehicles = [];
  const handleINVPagination = (event, value) => {
    //arrayVehicles = [];
    //setVehicles([]);
    dispatch(
      invoicePendingListByDealer(selectFilterDealer, "PENDING", value, 10)
    );
  };
  const handleModalPayInvoiceClose = () => {
    setIsModalPayInvoice(false);
  };
  const handleModalPayInvoiceOpen = (obj) => {
    setIdInvoice(obj.invoiceNumber);
    setIsModalPayInvoice(true);
  };

  const handlePayInvoice = () => {
    dispatch(resetInvoicesList());
    dispatch(PayingInvoiceByNumberAction(selectFilterDealer, idInvoice));
    setIsModalPayInvoice(false);
  };

  const selectFields = {
    dealer: {
      label: "Filter By Dealer",
      name: "dealer",
      value: selectFilterDealer,
      setCustomObjectData: setSelectFilterDealer,
      options: selectDataDealer,
      width: "320px",
    },
  };

  useEffect(() => {
    if (selectFilterDealer !== firstOption.value && selectFilterDealer) {
      dispatch(resetInvoicesList());
      arrayVehicles = [];
      setVehicles([]);
      dispatch(
        invoicePendingListByDealer(selectFilterDealer, "PENDING", 1, 10)
      );
    }
  }, [selectFilterDealer]);

  useEffect(() => {
    arrayVehicles = [];
    setVehicles([]);
    dispatch(resetInvoicesList());
    dispatch(getDealersListAction());
  }, []);

  useEffect(() => {
    if (invoices?.length > 0) {
      invoices?.forEach((item) => {
        item?.invoices?.forEach((invoice) => {
          invoice?.vehicles?.forEach((vehicle) => {
            arrayVehicles.push(vehicle);
          });
        });
      });

      arrayVehicles.forEach((vehicle) => {
        vehicle.description = "";
      });

      arrayVehicles.forEach((vehicle) =>
        vehicle.tasks.forEach((task, index, arr) => {
          vehicle.description +=
            index !== 0 ? "," + task?.name : "" + task?.name;
        })
      );
    }
  }, [invoices]);

  useEffect(() => {
    if (arrayVehicles?.length > 0) {
      setVehicles(arrayVehicles);
    }
  }, [arrayVehicles]);

  useEffect(() => {
    if (dealerList) {
      if (selectDataDealer?.length > 0) {
        setSelectFilterDealer(selectDataDealer?.[3]?.value);
      } else {
        setSelectFilterDealer(selectDataDealer?.[0]?.value);
      }
    }
  }, [dealerList]);

  return (
    <View
      isLoading={isLoading}
      selectFields={selectFields}
      selectFilterDealer={selectFilterDealer}
      invoiceIsLoading={invoicesIsLoading}
      invoices={invoices}
      handleINVPagination={handleINVPagination}
      isModalPayInvoice={isModalPayInvoice}
      handleModalPayInvoiceClose={handleModalPayInvoiceClose}
      handleModalPayInvoiceOpen={handleModalPayInvoiceOpen}
      handlePayInvoice={handlePayInvoice}
    />
  );
};
