import { put, call, select, takeLatest } from "redux-saga/effects";
import {
  GET_INVOICE_BY_DEALER_AND_STATUS_START,
  GET_INVOICES_COMPLETE,
  INVOICE_IS_LOADING,
  GET_INVOICES_PENDING_BY_DEALER_START,
  PAYING_INVOICE_BY_NUMBER_START,
  PAYING_INVOICE_BY_NUMBER_COMPLETE,
  GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_START,
  GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_COMPLETE,
  IS_LOADING,
  IS_DONE,
  GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_COMPLETE,
  GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_START,
  SET_INVOICE_NUMBER_DASHBOARD_COMPLETE,
  SET_INVOICE_NUMBER_DASHBOARD_START,
} from "../actionType";
import { apiCall } from "../../utils/apiCall";
import {
  INVOICE_DASBOARD_LIST,
  INVOICE_PENDING_LIST,
  PAYING_INVOICE_BY_NUMBER,
  INVOICE_PAID_LIST,
  GENERATE_INVOICE_DASHBOARD,
} from "../../utils/urls";
import { snackbarAction, isLoadingAction } from "../actions/utilityActions";
import { tokenSelector } from "../selector";

function* getInvoicesbyDealerAndStatus({
  idDealer,
  statusInvoice,
  pageNumber,
  size,
}) {
  yield put({ type: INVOICE_IS_LOADING, isLoading: true });
  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${INVOICE_DASBOARD_LIST}/${idDealer}/${statusInvoice}/${pageNumber}/${size}`,
      null,
      token,
      "GET"
    );
    yield put({ type: GET_INVOICES_COMPLETE, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put({ type: INVOICE_IS_LOADING, isLoading: false });
}

function* getInvoicesbyDealerAndStatusAll({ idDealer, statusInvoice }) {
  yield put({ type: IS_LOADING, isLoading: true });
  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${INVOICE_DASBOARD_LIST}/${idDealer}/${statusInvoice}`,
      null,
      token,
      "GET"
    );
    yield put({
      type: GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_COMPLETE,
      result,
    });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error?.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put({ type: IS_LOADING, isLoading: false });
}

function* getInvoicesPendingbyDealer({
  idDealer,
  statusInvoice,
  pageNumber,
  size,
}) {
  yield put({ type: INVOICE_IS_LOADING, isLoading: true });
  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${INVOICE_PENDING_LIST}/${idDealer}/${statusInvoice}/${pageNumber}/${size}`,
      null,
      token,
      "GET"
    );
    yield put({ type: GET_INVOICES_COMPLETE, result });
  } catch (error) {
    if (error?.response?.status !== 404) {
      yield put(
        snackbarAction({
          open: true,
          type: "error",
          message: error.response?.data.message || "Internal Error!",
        })
      );
    }
  }

  yield put({ type: INVOICE_IS_LOADING, isLoading: false });
}

function* payingInvoice({ idDealer, noInvoice }) {
  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${PAYING_INVOICE_BY_NUMBER}/${idDealer}/${noInvoice}`,
      null,
      token,
      "PUT"
    );
    yield put(
      snackbarAction({
        open: true,
        type: "success",
        message: result?.data?.message || "Satisfactory payment",
      })
    );
    yield put({
      type: GET_INVOICES_PENDING_BY_DEALER_START,
      idDealer: idDealer,
      statusInvoice: "PENDING",
      pageNumber: 1,
      size: 10,
    });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error?.response?.data.message || "Internal Error!",
      })
    );
  }
}

function* getInvoicesPaidbyDealerAndStatusAndDate({
  idDealer,
  statusInvoice,
  startDate,
  endDate,
  pageNumber,
  size,
}) {
  yield put({ type: INVOICE_IS_LOADING, isLoading: true });

  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${INVOICE_PAID_LIST}/${idDealer}/${startDate}/${endDate}/${statusInvoice}/${pageNumber}/${size}`,
      null,
      token,
      "GET"
    );
    yield put({ type: GET_INVOICES_COMPLETE, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put({ type: INVOICE_IS_LOADING, isLoading: false });
}

function* generateInvoiceNumber({ idDealer }) {
  yield put({ type: INVOICE_IS_LOADING, isLoading: true });
  yield put({ type: IS_DONE, isDone: false });
  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${GENERATE_INVOICE_DASHBOARD}/${idDealer}`,
      null,
      token,
      "PUT"
    );
    yield put({ type: SET_INVOICE_NUMBER_DASHBOARD_COMPLETE, result });
    yield put({ type: IS_DONE, isDone: true });
    yield put(
      snackbarAction({
        open: true,
        type: "success",
        message: result?.data?.message || "Invoice created successfully",
      })
    );
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }
  yield put({ type: INVOICE_IS_LOADING, isLoading: false });
}

export default function* watchInvoices() {
  yield takeLatest(
    GET_INVOICE_BY_DEALER_AND_STATUS_START,
    getInvoicesbyDealerAndStatus
  );
  yield takeLatest(
    GET_INVOICES_PENDING_BY_DEALER_START,
    getInvoicesPendingbyDealer
  );
  yield takeLatest(PAYING_INVOICE_BY_NUMBER_START, payingInvoice);
  yield takeLatest(
    GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_START,
    getInvoicesPaidbyDealerAndStatusAndDate
  );
  yield takeLatest(
    GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_START,
    getInvoicesbyDealerAndStatusAll
  );

  yield takeLatest(SET_INVOICE_NUMBER_DASHBOARD_START, generateInvoiceNumber);
}
