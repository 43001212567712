import sdtImg from "../assets/images/sdt.png";
import sdkmImg from "../assets/images/sdkm.png";
import sdkhImg from "../assets/images/sdkh.png";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import Invoice from "../Views/PdfReports/PdfDasboardAllApprovedInvoices/components/Invoice";

export const downloadInvoice = (invoices, isPaid, isDashboard) => {
  let imgCompany;
  //kia Homestead

  if (isDashboard) {
    if (invoices?.idDealer === "63dbfcdc0a704718d70d4103") {
      imgCompany = sdkhImg;
    } else if (invoices?.idDealer === "62f4fe48caa1d12d1a8104af") {
      //kia Miami
      imgCompany = sdkmImg;
    } else if (invoices?.idDealer === "62f4f80bcaa1d12d1a80b9cd") {
      //soutd date toyota
      imgCompany = sdtImg;
    }
    pdf(<Invoice invoice={invoices} imgCompany={imgCompany} isPaid={isPaid} />)
      .toBlob()
      .then((blob) => saveAs(blob, `Invoice_${invoices?.invoiceNumber}.pdf`));
  } else {
    if (invoices[0].dealer?._id === "63dbfcdc0a704718d70d4103") {
      imgCompany = sdkhImg;
    } else if (invoices[0].dealer?._id === "62f4fe48caa1d12d1a8104af") {
      //kia Miami
      imgCompany = sdkmImg;
    } else if (invoices[0].dealer?._id === "62f4f80bcaa1d12d1a80b9cd") {
      //soutd date toyota
      imgCompany = sdtImg;
    }
    pdf(
      <Invoice
        invoices={invoices}
        invoice={invoices[0]}
        imgCompany={imgCompany}
        isPaid={isPaid}
      />
    )
      .toBlob()
      .then((blob) =>
        saveAs(blob, `Invoice_${invoices[0]?.invoice_general_number}.pdf`)
      );
  }
};

export const isEmail = (email) => {
  /* eslint-disable no-useless-escape */
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};
