import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingSelector,
  dealersSelector,
  invoicesSelector,
  invoicesIsLoadingSelector,
} from "../../Redux/selector";
import { View } from "./view";
import { getDealersListAction } from "../../Redux/actions/dealerAction";
import {
  resetInvoicesList,
  invoicePaidListByDealerAndStatusAndDateAction,
} from "../../Redux/actions/invoiceAction";
import dayjs from "dayjs";
export const PaidInvoices = () => {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => invoicesSelector(state));
  const isLoading = useSelector((state) => isLoadingSelector(state));
  const dealerList = useSelector((state) => dealersSelector(state));
  const [isModalPayInvoice, setIsModalPayInvoice] = useState(false);
  const invoicesIsLoading = useSelector((state) =>
    invoicesIsLoadingSelector(state)
  );
  const selectDataDealer = dealerList?.items?.map((items) => ({
    value: items?._id,
    text: items?.name,
  }));
  const firstOption = { value: "Select a Dealer", text: "Select a Dealer" };
  selectDataDealer?.unshift(firstOption);
  const [selectFilterDealer, setSelectFilterDealer] =
    useState(selectDataDealer);
  const [vehicles, setVehicles] = useState([]);
  const [idInvoice, setIdInvoice] = useState();
  const [isValid, setIsvalid] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs().endOf("month"));
  let arrayVehicles = [];
  const handleINVPagination = (event, value) => {
    dispatch(
      invoicePaidListByDealerAndStatusAndDateAction(
        selectFilterDealer,
        "PAID",
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        value,
        10
      )
    );
  };

  const selectFields = {
    dealer: {
      label: "Filter By Dealer",
      name: "dealer",
      value: selectFilterDealer,
      setCustomObjectData: setSelectFilterDealer,
      options: selectDataDealer,
      width: "320px",
    },
  };

  useEffect(() => {
    if (isValid) {
      dispatch(resetInvoicesList());
      arrayVehicles = [];
      setVehicles([]);
      dispatch(
        invoicePaidListByDealerAndStatusAndDateAction(
          selectFilterDealer,
          "PAID",
          dayjs(startDate).format("YYYY-MM-DD"),
          dayjs(endDate).format("YYYY-MM-DD"),
          1,
          10
        )
      );
    } else {
      dispatch(resetInvoicesList());
      arrayVehicles = [];
      setVehicles([]);
    }
  }, [startDate, endDate, selectFilterDealer, isValid]);

  useEffect(() => {
    arrayVehicles = [];
    setVehicles([]);
    dispatch(resetInvoicesList());
    dispatch(getDealersListAction());
  }, []);

  useEffect(() => {
    if (
      dayjs(startDate).isValid() &&
      dayjs(endDate).isValid() &&
      (selectFilterDealer !== firstOption.value ||
        selectFilterDealer !== "Select a Dealer")
    ) {
      setIsvalid(true);
    } else {
      dispatch(resetInvoicesList());
      setIsvalid(false);
    }
  }, [startDate, endDate, selectFilterDealer, invoices]);

  useEffect(() => {
    if (invoices?.length > 0) {
      invoices?.forEach((item) => {
        item?.invoices?.forEach((invoice) => {
          invoice?.vehicles?.forEach((vehicle) => {
            arrayVehicles.push(vehicle);
          });
        });
      });

      arrayVehicles.forEach((vehicle) => {
        vehicle.description = "";
      });

      arrayVehicles.forEach((vehicle) =>
        vehicle.tasks.forEach((task, index, arr) => {
          vehicle.description +=
            index !== 0 ? "," + task?.name : "" + task?.name;
        })
      );
    }
  }, [invoices]);

  useEffect(() => {
    if (arrayVehicles?.length > 0) {
      setVehicles(arrayVehicles);
    }
  }, [arrayVehicles]);

  useEffect(() => {
    if (dealerList) {
      if (selectDataDealer?.length > 0) {
        setSelectFilterDealer(selectDataDealer?.[3]?.value);
      } else {
        setSelectFilterDealer(selectDataDealer?.[0]?.value);
      }
    }
  }, [dealerList]);

  return (
    <View
      isLoading={isLoading}
      selectFields={selectFields}
      selectFilterDealer={selectFilterDealer}
      invoiceIsLoading={invoicesIsLoading}
      invoices={invoices}
      handleINVPagination={handleINVPagination}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      endDate={endDate}
      startDate={startDate}
      isValid={isValid}
    />
  );
};
