import { get } from "lodash";
import dayjs from "dayjs";
import {
  GET_INVOICES_COMPLETE,
  INVOICE_IS_LOADING,
  RESET_INVOICES_LIST,
  GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_COMPLETE,
  SET_INVOICE_NUMBER_DASHBOARD_COMPLETE,
} from "../actionType";
import { act } from "@testing-library/react";
import {
  setLocalStorage,
  OBJ_INVOICE_PDF_APPROVED,
  rmLocalStorage,
} from "../../utils/localStorage";
const initialState = {
  invoices: [],
  isLoading: false,
  invoicesPDFList: {},
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICES_COMPLETE:
      return {
        ...state,
        invoices: get(action, "result.data.items"),
      };
    case SET_INVOICE_NUMBER_DASHBOARD_COMPLETE:
      const invoice_number = action?.result?.data?.invoice_number;
      const listpdf = {
        ...state.invoicesPDFList,
        invoiceNumber: invoice_number,
      };
      setLocalStorage(JSON.stringify(listpdf), OBJ_INVOICE_PDF_APPROVED);
      return {
        ...state,
        invoicesPDFList: {
          ...state.invoicesPDFList,
          invoiceNumber: invoice_number,
        },
      };
    case GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_COMPLETE:
      let list = action?.result?.data;
      let arrayVehicles = [];
      let totalInvoice = 0.0;

      if (list?.length > 0) {
        list?.forEach((invoice) => {
          invoice?.vehicles?.forEach((vehicle) => arrayVehicles.push(vehicle));
          totalInvoice += invoice?.total;
        });

        arrayVehicles.forEach((vehicle) => {
          vehicle.description = "";
        });

        arrayVehicles.forEach((vehicle) =>
          vehicle.tasks.forEach((task, index, arr) => {
            vehicle.description +=
              index !== 0 ? "," + task?.name : "" + task?.name;
          })
        );
      }
      let dateInvoice = dayjs(new Date()).format("DD/MM/YYYY");
      let dataPDF = {
        totalInvoice: totalInvoice,
        dateInvoice: dateInvoice,
        items: arrayVehicles,
        nameDealer: list?.[0]?.dealer?.name,
        address: list?.[0]?.dealer?.address,
        idDealer: list?.[0]?.dealer?._id,
        dealerImg: list?.[0]?.dealer?.dealer_img,
        nameManager:
          list?.[0]?.user_manager?.first_name +
          " " +
          list?.[0]?.user_manager?.last_name,
      };

      return {
        ...state,
        invoicesPDFList: dataPDF,
      };

    case INVOICE_IS_LOADING:
      return {
        ...state,
        isLoading: get(action, "isLoading"),
      };
    case RESET_INVOICES_LIST:
      return {
        ...state,
        invoices: [],
      };

    default:
      return state;
  }
};
