import { put, call, select, takeLatest } from "redux-saga/effects";

import { snackbarAction } from "../actions/utilityActions";
import { USER_INFO_START, USER_INFO_COMPLETE } from "../actionType";
import { apiCall } from "../../utils/apiCall";
import { GET_USER_INFO } from "../../utils/urls";
import { tokenSelector } from "../selector";
import { setLocalStorage, EMAIL } from "../../utils/localStorage";

function* getUser() {
  try {
    const token = yield select(tokenSelector);
    const result = yield call(apiCall, GET_USER_INFO, null, token, "GET");

    yield setLocalStorage(result.data.email, EMAIL);
    yield put({ type: USER_INFO_COMPLETE, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error?.response?.data.message || "Internal Error",
      })
    );
  }
}

export default function* watchUser() {
  yield takeLatest(USER_INFO_START, getUser);
}
