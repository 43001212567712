import { call, select, put, takeLatest } from "redux-saga/effects";

import { isLoadingAction, snackbarAction } from "../actions/utilityActions";
import { SEARCH_QUERY_START, TASK_IS_LOADING } from "../actionType";
import { taskIsLoadingSelector, tokenSelector } from "../selector";
import { apiCall } from "../../utils/apiCall";

function* searchQuery({ url, query, page, actionForRedux }) {
  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${url}/${query}/${page}/10`,
      null,
      token,
      "GET"
    );

    if (!result.data.items)
      yield put(
        snackbarAction({
          open: true,
          type: "warning",
          message: result.data.message,
        })
      );

    yield put({ type: actionForRedux, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  const taskIsLoading = yield select(taskIsLoadingSelector);

  yield put(isLoadingAction(false));

  if (taskIsLoading) yield put({ type: TASK_IS_LOADING, isLoading: false });
}

export default function* watchSearch() {
  yield takeLatest(SEARCH_QUERY_START, searchQuery);
}
