import { useSelector } from "react-redux";
import { TextField, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Button } from "../Button/Button";

import { isLoadingSelector } from "../../Redux/selector/";

export const Form = ({
  formHeader,
  inputFields,
  buttonTitle,
  formBottom,
  formHeight,
  handleSubmit,
  tittle,
  formWidth,
  formSubtitle,
  marginBottom,
}) => {
  const isLoading = useSelector((state) => isLoadingSelector(state));

  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "grid",
        placeItems: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Grid
        sx={{
          maxWidth: 450,
          minHeight: formHeight,
          backgroundColor: "#fff",
        }}
      >
        <form
          noValidate
          autoComplete="off"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
            width: formWidth || "450px",
          }}
          onSubmit={handleSubmit}
        >
          {formHeader}
          <Typography sx={{ width: "90%", margin: "10px auto" }} variant="h4">
            {tittle}
          </Typography>
          {formSubtitle}
          {Object.keys(inputFields).map((key) => (
            <TextField
              key={key}
              id={key}
              type={inputFields[key].type}
              error={inputFields[key].error}
              sx={{ width: "90%", margin: "10px auto" }}
              inputRef={inputFields[key].ref}
              label={inputFields[key].label}
              variant="outlined"
              placeholder={inputFields[key].placeholder}
              fullWidth
              InputProps={inputFields[key]?.inputProps}
            />
          ))}

          <Button
            title={buttonTitle}
            isLoading={isLoading}
            marginBottom={marginBottom || "20px"}
          />
          {formBottom}
        </form>
      </Grid>
    </div>
  );
};

Form.propTypes = {
  inputFields: PropTypes.object.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  formHeight: PropTypes.number,
  marginBottom: PropTypes.string,
  tittle: PropTypes.string,
};
