import { Route, Routes as Routess, Navigate } from "react-router-dom";
import {
  SignInView,
  DashboardView,
  TaskView,
  PendingInvoicesView,
  PaidInvoicesView,
  UsersView,
  SendCodeChangePasswordView,
  ChangePasswordView,
} from "./Views/";
import { MainLayout } from "./layout";

export const Routes = () => {
  return (
    <Routess>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route exact path="/" element={<SignInView />} />
      <Route exact path="/login" element={<SignInView />} />
      <Route
        exact
        path="/send_code_recovery_password"
        element={<SendCodeChangePasswordView />}
      />
      <Route exact path="/change_password" element={<ChangePasswordView />} />
      <Route
        path="*"
        element={
          <h1 style={{ textAlign: "center", margin: "100px auto" }}>
            404 NOT_FOUND
          </h1>
        }
      />

      <Route element={<MainLayout />}>
        <Route path="/dashboard" element={<DashboardView />} />
        <Route path="/task" element={<TaskView />} />
        <Route path="/pending_invoices" element={<PendingInvoicesView />} />
        <Route path="/paid_invoices" element={<PaidInvoicesView />} />
        <Route path="/users" element={<UsersView />} />
      </Route>
    </Routess>
  );
};
