import { Grid, Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Loading, NoData, SearchBar } from "../../components";
import { ReactComponent as SelectDealer } from "../../assets/images/select_dealer.svg";
import { Invoices } from "./components/Invoices/Invoices";
import { theme } from "../../utils/theme";
import { PayInvoiceModal } from "./components/payInvoiceConfirmationModal/PayInvoiceModal";

export const View = ({
  isLoading,
  invoices,
  selectFields,
  selectFilterDealer,
  invoiceIsLoading,
  handleINVPagination,
  handleModalPayInvoiceClose,
  isModalPayInvoice,
  isLoadingModal,
  infoPay,
  isLoadingModalAction,
  handleModalPayInvoiceOpen,
  handlePayInvoice,
}) => {
  return (
    <>
      <Helmet title="Pending Invoices - Track Detailing" />
      <Grid item xs={12} sx={{ marginBottom: "15px" }}>
        <SearchBar selectFields={selectFields} />
      </Grid>

      <Box sx={{ marginLeft: "20px" }}>
        <Typography fontWeight="bold" variant="h6" gutterBottom>
          Pending Invoices
        </Typography>
      </Box>
      <PayInvoiceModal
        isModal={isModalPayInvoice}
        handleClose={handleModalPayInvoiceClose}
        isLoading={isLoadingModal}
        isLoadingAction={isLoadingModalAction}
        handlePayInvoice={handlePayInvoice}
      />

      {selectFilterDealer === "Select a Dealer" && (
        <Grid
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "70vh" }}
          container
          spacing={0}
        >
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <SelectDealer />
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      )}
      {selectFilterDealer !== "Select a Dealer" && selectFilterDealer && (
        <div sx={{ flexGrow: 1 }}>
          <Grid container>
            <Invoices
              handleModalPayInvoiceOpen={handleModalPayInvoiceOpen}
              handlePayInvoice={handlePayInvoice}
              isLoading={invoiceIsLoading}
              invoices={invoices}
              handleINVPagination={handleINVPagination}
            />
          </Grid>
        </div>
      )}
    </>
  );
};

View.proptTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectFields: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  invoiceIsLoading: PropTypes.bool.isRequired,
  handleINVPagination: PropTypes.func.isRequired,
};
