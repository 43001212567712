import { put, call, select, takeLatest } from "redux-saga/effects";
import {
  GET_DEALERS_START,
  GET_DEALERS_COMPLETE,
  DEALER_IS_LOADING,
} from "../actionType";
import { apiCall } from "../../utils/apiCall";
import { DEALER, DEALER_BY_VENDOR } from "../../utils/urls";
import { snackbarAction } from "../actions/utilityActions";
import { tokenSelector } from "../selector";

function* getdealer() {
  yield put({ type: DEALER_IS_LOADING, isLoading: true });

  try {
    const token = yield select(tokenSelector);
    const result = yield call(apiCall, `${DEALER_BY_VENDOR}`, null, token, "GET");

    yield put({ type: GET_DEALERS_COMPLETE, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put({ type: DEALER_IS_LOADING, isLoading: false });
}

export default function* watchTasks() {
  yield takeLatest(GET_DEALERS_START, getdealer);
}
