const isBrowser = window !== "undefined";

export const setLocalStorage = (value, name = GET_TOKEN) =>
  isBrowser && localStorage.setItem(name, value);

export const getLocalStorage = (name = GET_TOKEN) =>
  isBrowser && localStorage.getItem(name);

export const rmLocalStorage = (name = GET_TOKEN) =>
  isBrowser && localStorage.removeItem(name);

export const rmLocalObjInvoicePdfApprovedStorage = (
  name = OBJ_INVOICE_PDF_APPROVED
) => isBrowser && localStorage.removeItem(name);

export const GET_TOKEN = "trackDetailingX_access_token";
export const REFRESH_TOKEN = "trackDetailingX_refresh_token";
export const ORGANIZATION = "trackDetailingX_oranization";
export const EMAIL = "trackDetailingX_access_email";

export const OBJ_INVOICE_PDF_APPROVED =
  "trackDetailingX_obj_invoice_pdf_approved";
