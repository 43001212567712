import { combineReducers } from "redux";

import { utilityReducer } from "./utilityReducer";
import { loginReducer } from "./loginReducer";
import { userReducer } from "./userReducer";
import { taskReducer } from "./taskReducer";
import { dealerReducer } from "./dealerReducer";
import { invoiceReducer } from "./invoiceReducer";
import { userListReducer } from "./userListReducer";
export default combineReducers({
  utilityReducer,
  loginReducer,
  userReducer,
  taskReducer,
  invoiceReducer,
  dealerReducer,
  userListReducer,
});
