import axios from "axios";
import { URL_API } from "../utils/urls";
import {
  getLocalStorage,
  rmLocalStorage,
  setLocalStorage,
  GET_TOKEN,
  REFRESH_TOKEN,
} from "../utils/localStorage";
import { ASSESS_TOKEN as ASSESS_TOKEN_COMPLETE } from "../Redux/actionType/";

import { store } from "../index";

/** Refresh Token implementation */

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response.data.status_code === 401 &&
      error.response.config.url !== `${URL_API}auth`
    ) {
      return refreshTokenFunction(error);
    } else if (
      error.response.data.status_code === 401 &&
      error.response.config.url === `${URL_API}auth`
    ) {
      return error.response;
    } else {
      return Promise.reject(error);
    }
  }
);

const refreshTokenFunction = (error) => {
  const originalRequest = error.config;

  // if the refresh token is expired, go to login page
  if (
    error.response.data.status_code === 401 &&
    originalRequest.url === `${URL_API}refresh_token`
  ) {
    rmLocalStorage(GET_TOKEN);
    rmLocalStorage(REFRESH_TOKEN);
    window.location.href = "/";
    return Promise.reject(error);
  }

  if (error.response.data.status_code === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = getLocalStorage(REFRESH_TOKEN);
    return axios
      .post(`${URL_API}refresh_token`, null, {
        headers: { Authorization: `JWT ${refreshToken}` },
      })
      .then((res) => {
        if (res.status === 200) {
          const access_token = res.data.access_token;
          setLocalStorage(access_token, GET_TOKEN);

          store.dispatch({ type: ASSESS_TOKEN_COMPLETE, access_token });

          originalRequest.headers["Authorization"] = "JWT " + access_token;
          return axios(originalRequest);
        }
      });
  }
  return Promise.reject(error);
};

export const authCall = (url, data, method) =>
  axios({
    method,
    url: URL_API + url,
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });

export const apiCall = (url, data, token, method, headers, responseType) =>
  axios({
    method,
    url: URL_API + url,
    data,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
      ...headers,
    },
    ...responseType,
  });

export const multipleCall = (url, data, token, method) => {
  return axios
    .all(
      data?.map((link) =>
        axios.get(`${URL_API + url}/${link}`, {
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        })
      )
    )
    .then(axios.spread((...res) => res.map((data) => data.data)));
};
