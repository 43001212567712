import { Grid, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Loading, NoData, SearchBar } from "../../components";
import { ReactComponent as Logo } from "../../assets/images/select_dealer.svg";
import { Invoices } from "./components/Invoices/Invoices";
import { theme } from "../../utils/theme";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

export const View = ({
  isLoading,
  invoices,
  selectFields,
  selectFilterDealer,
  invoiceIsLoading,
  handleINVPagination,
  selectFilterByOptions,
  handlePdfInvoiceApproved,
}) => {
  return (
    <>
      <Helmet title="Dashboard - Track Detailing" />
      <Grid item xs={12} sx={{ marginBottom: "15px" }}>
        <SearchBar selectFields={selectFields} />
      </Grid>

      {selectFilterDealer === "Select a Dealer" && (
        <Grid
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "70vh" }}
          container
          spacing={0}
        >
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <Logo />
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      )}
      {selectFilterDealer !== "Select a Dealer" && selectFilterDealer && (
        <div sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Invoices
              isLoading={invoiceIsLoading}
              invoices={invoices}
              handleINVPagination={handleINVPagination}
            />
          </Grid>
        </div>
      )}
      {selectFilterByOptions === "APPROVED" &&
        invoices?.length > 0 &&
        selectFilterDealer !== "Select a Dealer" &&
        invoiceIsLoading === false && (
          <Box
            sx={{
              paddingTop: "15px",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              sx={{
                height: "50px",
                width: "230px",
                color: "white",
                fontWeight: 700,
              }}
              variant="contained"
              color="buttons"
              onClick={handlePdfInvoiceApproved}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                "Generate New Invoices"
              )}
            </Button>
          </Box>
        )}
    </>
  );
};

View.proptTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectFields: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  invoiceIsLoading: PropTypes.bool.isRequired,
  handleINVPagination: PropTypes.func.isRequired,
};
