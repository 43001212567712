import { View } from "./view";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingAction,
  isDoneAction,
} from "../../Redux/actions/utilityActions";
import { tokenSelector, isDoneSelector } from "../../Redux/selector/";
import { useNavigate } from "react-router-dom";
import { isEmail } from "../../utils/generaluFunctions";
import { forgotPassAction } from "../../Redux/actions/forgotPasswordAction";
export const SendCodeChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => tokenSelector(state));
  const isDone = useSelector((state) => isDoneSelector(state));
  const emailRef = useRef(null);
  const [emailError, setEmailError] = useState(false);

  const inputFields = {
    email: {
      type: "text",
      label: "Email",
      ref: emailRef,
      error: emailError,
      placeholder: "example@email.com",
      inputProps: {
        shrink: true,
      },
    },
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]);

  useEffect(() => {
    const email = emailRef.current.value;
    if (isDone) {
      navigate("/change_password", { state: { email: email } });
      dispatch(isDoneAction(false));
    }
  }, [isDone]);

  useEffect(() => {
    dispatch(isDoneAction(false));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = emailRef.current.value;

    // form validation;
    if (!email || !isEmail(email)) {
      setEmailError(true);
    }

    emailError && email && setEmailError(false);

    if (!email || !isEmail(email)) return;

    dispatch(isLoadingAction(true));
    dispatch(
      forgotPassAction({
        email: email.replace(/\s+/g, ""),
        organization: "TrackDetailing",
      })
    );
  };
  return <View handleSubmit={handleSubmit} inputFields={inputFields} />;
};
