import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import { width } from "@mui/system";

// Styling

export const Navbar = ({ listItem, marginTop, marginBottom }) => {
  return (
    <List sx={{ marginTop: marginTop, marginBottom: marginBottom }}>
      <div>
        {listItem?.map((item) => (
          <ListItem
            button
            key={item?.name}
            component={Link}
            to={item?.to}
            onClick={item?.onClick}
            sx={{
              border: 0.5,
              borderColor: "white",
              borderRadius: "15px",
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "2px",
              width: "75%",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <ListItemIcon sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <SvgIcon component={item.icon} sx={{ width: "2em" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary={item.name} />
          </ListItem>
        ))}
      </div>
    </List>
  );
};

Navbar.propTypes = {
  listItem: PropTypes.array.isRequired,
};
