import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { theme } from "../../../../utils/theme";
import { Filter, Loading, Pagination } from "../../../../components";
import { NoData } from "../../../../components/NoData/noData";

export const Tasks = ({
  tasks,
  openModalAddTask,
  handleUpdateTask,
  isLoading,
  handleTSKPagination,
  handleSearch,
  searchValue,
}) => {
  return (
    <Grid item xs={12}>
      <Paper sx={{ padding: "15px 20px" }}>
        <Filter
          title={"Tasks"}
          btnTitle="Add Task"
          btnWidth="145px"
          btnColor="buttons"
          openModalHandler={openModalAddTask}
          onChange={handleSearch}
          value={searchValue}
        />

        <Loading isLoading={isLoading} />
        <NoData data={!!tasks?.items?.length} isLoading={isLoading} />

        {!isLoading && tasks?.items?.length && (
          <TableContainer
            sx={{
              marginTop: theme.spacing(1),
              overflowX: "auto",
              marginLeft: "auto",
              paddingRight: "15px",
              maxHeight: "75vh",
            }}
          >
            <Table
              sx={{ marginTop: theme.spacing(1) }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead
                sx={{
                  "& th": {
                    color: theme.palette.white,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: "bold",
                  },
                }}
              >
                <TableRow>
                  <TableCell>No </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell align="right">Draw</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks?.items?.map((tsk, index) => (
                  <TableRow hover key={tsk._id}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tsk.name}
                    </TableCell>
                    <TableCell>
                      {tsk?.cost
                        ? String(tsk.cost).charAt(0) === "$"
                          ? "$" + parseFloat(tsk.cost.slice(1)).toFixed(2)
                          : "$" + parseFloat(tsk.cost).toFixed(2)
                        : "$0.00"}
                    </TableCell>

                    <TableCell align="right" sx={{ cursor: "pointer" }}>
                      <EditIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleUpdateTask.bind(this, tsk)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Pagination count={tasks?.total_pages} onChange={handleTSKPagination} />
      </Paper>
    </Grid>
  );
};

Tasks.propTypes = {
  openModalAddTask: PropTypes.func.isRequired,
  handleUpdateTask: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleTSKPagination: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};
