import { Typography, Link as LinkMaterial, Hidden } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Form } from "../../components";
import { ReactComponent as Logo } from "../../assets/images/new_logo.svg";
import { Grid } from "@mui/material";

// Styles

export const View = ({ inputFields, handleSubmit }) => {
  const renderFormBottom = (
    <>
      <Typography sx={{ alignSelf: "center" }}>
        <LinkMaterial component={Link} to="/">
          Back To Login
        </LinkMaterial>
      </Typography>
    </>
  );
  const renderFormSubtitle = (
    <Typography
      sx={{ alignSelf: "center", width: "90%", margin: "10px auto" }}
      variant="subtitle1"
      gutterBottom
    >
      Enter your email address and we will send email with instructions.
    </Typography>
  );
  return (
    <>
      <Helmet title="Send Code Recovery - Track Detailing" />
      <Grid item container spacing={0}>
        <Hidden only={["xs", "sm"]}>
          <Grid
            item
            container
            spacing={0}
            xs={12}
            md={6}
            direction="column"
            alignItems="center"
            justify="center"
            style={{
              minHeight: "100vh",
              backgroundColor: "#F2F2F2",
              display: "flex",
              placeItems: "center",
              width: "100%",
            }}
          >
            <Grid
              item={true}
              container
              spacing={0}
              xs={12}
              md={12}
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid item xs={7}>
                <Logo />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Grid
          item={true}
          container
          spacing={0}
          xs={12}
          md={6}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Form
            formSubtitle={renderFormSubtitle}
            tittle="Forgot Password"
            inputFields={inputFields}
            buttonTitle="Send Code"
            formBottom={renderFormBottom}
            handleSubmit={handleSubmit}
            marginBottom={"20px"}
          />
        </Grid>
      </Grid>
    </>
  );
};

View.propTypes = {
  inputFields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
