import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  theader: {
    fontSize: 12,
    fontStyle: "bold",
    paddingTop: 4,
    height: 25,
    borderColor: "black",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    textAlign: "center",
  },
  theaderRow1: {
    width: "15%",
    borderLeftWidth: 1,
  },
  theaderRow2: {
    width: "15%",
  },
  theaderRow3: {
    width: "22%",
  },
  theaderRow4: {
    width: "38%",
  },
  theaderRow5: {
    width: "10%",
  },

  tbody: {
    paddingRight: 3,
    fontSize: 10,
    paddingTop: 4,
    paddingLeft: 3,
    borderColor: "black",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  tbodyRow1: {
    width: "15%",
    borderLeftWidth: 1,
  },
  tbodyRow2: {
    width: "15%",
  },
  tbodyRow3: {
    width: "22%",
  },
  tbodyRow4: {
    width: "38%",
  },
  tbodyRow5: {
    width: "10%",
    textAlign: "right",
  },
});

const InvoiceTableRow = ({ invoice, invoices }) => {
  const TableHead = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={[styles.theaderRow1, styles.theader]}>
        <Text>Item</Text>
      </View>
      <View style={[styles.theaderRow2, styles.theader]}>
        <Text>Stock No</Text>
      </View>
      <View style={[styles.theaderRow3, styles.theader]}>
        <Text>VIN no</Text>
      </View>
      <View style={[styles.theaderRow4, styles.theader]}>
        <Text>Description</Text>
      </View>
      <View style={[styles.theaderRow5, styles.theader]}>
        <Text>Amount</Text>
      </View>
    </View>
  );

  return (
    <View>
      <TableHead />
      {invoices?.length > 0 &&
        invoices?.map((item, index) => (
          <Fragment key={index}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={[styles.tbody, styles.tbodyRow1]}>
                <Text>
                  {item?.vehicles[0]?.make.toLowerCase() +
                    " " +
                    item?.vehicles[0]?.model.toLowerCase()}
                </Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow2]}>
                <Text>{item?.vehicles[0]?.stock} </Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow3]}>
                <Text>{item?.vehicles[0]?.vin}</Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow4]}>
                <Text>
                  {item?.vehicles[0]?.tasks?.map((itm, idx) => {
                    let text = idx === 0 ? itm?.name : ", " + itm?.name;
                    return text.toLowerCase();
                  })}
                </Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow5]}>
                <Text>
                  {item?.vehicles[0]?.total
                    ? String(item?.vehicles[0]?.total).charAt(0) === "$"
                      ? "$" +
                        parseFloat(item?.vehicles[0]?.total.slice(1)).toFixed(2)
                      : "$" + parseFloat(item?.vehicles[0]?.total).toFixed(2)
                    : "$0.00"}
                </Text>
              </View>
            </View>
          </Fragment>
        ))}

      {invoice?.items?.length > 0 &&
        invoice?.items.map((item, i) => (
          <Fragment key={i}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={[styles.tbody, styles.tbodyRow1]}>
                <Text>
                  {item.make.toLowerCase() + " " + item.model.toLowerCase()}
                </Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow2]}>
                <Text>{item.stock}</Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow3]}>
                <Text>{item.vin}</Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow4]}>
                <Text>{item.description.toLowerCase()}</Text>
              </View>
              <View style={[styles.tbody, styles.tbodyRow5]}>
                <Text>
                  {item?.total
                    ? String(item?.total).charAt(0) === "$"
                      ? "$" + parseFloat(item?.total.slice(1)).toFixed(2)
                      : "$" + parseFloat(item?.total).toFixed(2)
                    : "$0.00"}
                </Text>
              </View>
            </View>
          </Fragment>
        ))}
    </View>
  );
};

export default InvoiceTableRow;
