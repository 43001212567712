import { styled } from "@mui/material/";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import DateFnsUtils from "@date-io/dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button, DatePicker, Select } from "..";

const PREFIX = "MySearchBar";
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
};
const Root = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  [`&.${classes.paper}`]: {
    padding: "11px",
  },
}));

export const SearchBar = ({ selectFields }) => {
  return (
    <Root classes={{ root: classes.paper }}>
      <form className={classes.root} noValidate autoComplete="off">
        {Object.keys(selectFields).map((key) => (
          <Select
            width={selectFields[key].width}
            key={key}
            id={key}
            label={selectFields[key].label}
            name={selectFields[key].name}
            options={selectFields[key].options}
            handleOnChange={(event) => {
              selectFields[key].setCustomObjectData(event.target.value);
            }}
            value={selectFields[key].value}
          />
        ))}
      </form>
    </Root>
  );
};

SearchBar.propTypes = {
  isLoading: PropTypes.bool,
  btnTitle: PropTypes.string,
};
