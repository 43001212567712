import React from "react";
import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { theme } from "../../../../utils/theme";
import { Loading, Pagination } from "../../../../components";
import { NoData } from "../../../../components/NoData/noData";

import { CollapsibleTable } from "../CollapsibleTable/collapseibleTable";

export const Invoices = ({
  invoices,
  isLoading,
  handleINVPagination,
  handleModalPayInvoiceOpen,
}) => {
  return (
    <Grid item xs={12}>
      {!isLoading && !invoices?.length && (
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "300px",
          }}
        >
          <NoData data={!!invoices?.length} isLoading={isLoading} />
        </Paper>
      )}
      {isLoading && (
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "300px",
          }}
        >
          <Loading isLoading={isLoading} />
        </Paper>
      )}
      {!isLoading && invoices?.length > 0 && (
        <div>
          <CollapsibleTable
            isLoading={isLoading}
            invoicesList={invoices}
            handleModalPayInvoiceOpen={handleModalPayInvoiceOpen}
          />
          <Pagination
            count={invoices?.total_pages}
            onChange={handleINVPagination}
          />
        </div>
      )}
    </Grid>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleINVPagination: PropTypes.func.isRequired,
};
