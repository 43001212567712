import { ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";
import { Routes } from "./Routes";
import { SnackbarAlert } from "./components/";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <SnackbarAlert />
    </ThemeProvider>
  );
}

export default App;
