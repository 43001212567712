import { Button as Btn } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

export const Button = ({
  title,
  isLoading,
  width = "90%",
  height = "50px",
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  onClick,
  icon,
  colorButton,
}) => {
  return (
    <Btn
      variant="contained"
      type="submit"
      onClick={onClick}
      color={colorButton || "primary"}
      sx={{
        color: "white",
        width: width || "100%",
        height: height || "100%",
        margin: `10px auto 20px`,
        marginBottom: marginBottom,
        marginTop: marginTop,
        marginLeft: marginLeft,
        marginRight: marginRight,
        borderRadius: 6,
        fontWeight: 600,
      }}
    >
      {icon && icon}
      {isLoading ? <CircularProgress sx={{ color: "white" }} /> : title}
    </Btn>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  icon: PropTypes.element,
  marginTop: PropTypes.string,
  colorButton: PropTypes.string,
};
