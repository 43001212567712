import { put, call, select, takeLatest } from "redux-saga/effects";
import {
  GET_TASKS_START,
  GET_TASKS_COMPLETE,
  SELECTED_TASK_COMPLETE,
  SELECTED_TASK_START,
  CREATE_TASK_START,
  SHOW_TASK_MODAL_COMPLETE,
  EDIT_TASK_START,
  EDIT_TASK_COMPLETE,
  TASK_FOR_DROPDOWN_START,
  TASK_FOR_DROPDOWN_COMPLETE,
  TASK_IS_LOADING,
} from "../actionType";
import { apiCall, multipleCall } from "../../utils/apiCall";
import { TASK } from "../../utils/urls";
import { isLoadingAction, snackbarAction } from "../actions/utilityActions";
import { tokenSelector } from "../selector";
//import { packageIsLoadingAction } from '../actions/packageAction';

function* getTasks({ pageNumber, size }) {
  yield put({ type: TASK_IS_LOADING, isLoading: true });

  try {
    const token = yield select(tokenSelector);
    const result = yield call(
      apiCall,
      `${TASK}/${pageNumber}/${size}`,
      null,
      token,
      "GET"
    );

    yield put({ type: GET_TASKS_COMPLETE, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put({ type: TASK_IS_LOADING, isLoading: false });
}

function* createTask({ task }) {
  try {
    const token = yield select(tokenSelector);
    const result = yield call(apiCall, TASK, task, token, "POST");

    yield put(
      snackbarAction({
        open: true,
        type: "success",
        message: result.data.message,
      })
    );

    yield put({ type: GET_TASKS_START, pageNumber: 1, size: 10 });
    yield put({ type: SHOW_TASK_MODAL_COMPLETE, isTaskModal: false });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put({ type: TASK_IS_LOADING, isLoading: false });
}

function* selectedTasks({ taskIds }) {
  try {
    const token = yield select(tokenSelector);

    const result = yield call(multipleCall, TASK, taskIds, token, "GET");
    yield put({ type: SELECTED_TASK_COMPLETE, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put(isLoadingAction(false));
  //yield put(packageIsLoadingAction(false));
  yield put({ type: TASK_IS_LOADING, isLoading: false });
}

function* editTask({ data: { _id, ...data } }) {
  try {
    const token = yield select(tokenSelector);
    const result = yield call(apiCall, `${TASK}/${_id}`, data, token, "PUT");

    yield put({ type: EDIT_TASK_COMPLETE, data: { _id, ...data } });

    yield put(
      snackbarAction({
        open: true,
        type: "success",
        message: result.data.message,
      })
    );

    yield put({ type: SHOW_TASK_MODAL_COMPLETE, isTaskModal: false });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error.response?.data.message || "Internal Error!",
      })
    );
  }

  yield put({ type: TASK_IS_LOADING, isLoading: false });
}

function* taskForDropdown() {
  try {
    const token = yield select(tokenSelector);
    const result = yield call(apiCall, TASK, null, token, "GET");

    yield put({ type: TASK_FOR_DROPDOWN_COMPLETE, result });
  } catch (error) {
    yield put(
      snackbarAction({
        open: true,
        type: "error",
        message: error?.response.data.message || "Internal Error!",
      })
    );
  }
}

export default function* watchTasks() {
  yield takeLatest(GET_TASKS_START, getTasks);
  yield takeLatest(CREATE_TASK_START, createTask);
  yield takeLatest(SELECTED_TASK_START, selectedTasks);
  yield takeLatest(EDIT_TASK_START, editTask);
  yield takeLatest(TASK_FOR_DROPDOWN_START, taskForDropdown);
}
