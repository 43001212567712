import { Grid, Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { ReactComponent as SelectDealer } from "../../assets/images/select_dealer.svg";
import { Invoices } from "./components/Invoices/Invoices";
import { SearchBar } from "./components/SearchBar/SearchBar";
import { theme } from "../../utils/theme";
import { PayInvoiceModal } from "./components/payInvoiceConfirmationModal/PayInvoiceModal";

export const View = ({
  isLoading,
  invoices,
  selectFields,
  selectFilterDealer,
  invoiceIsLoading,
  handleINVPagination,
  setStartDate,
  setEndDate,
  endDate,
  startDate,
  isValid,
}) => {
  return (
    <>
      <Helmet title=" Paid Invoices - Track Detailing" />
      <Grid item xs={12} sx={{ marginBottom: "15px" }}>
        <SearchBar
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          selectFields={selectFields}
          endDate={endDate}
          startDate={startDate}
        />
      </Grid>

      <Box sx={{ marginLeft: "20px" }}>
        <Typography fontWeight="bold" variant="h6" gutterBottom>
          Paid Invoices
        </Typography>
      </Box>

      {!isValid && (
        <Grid
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "70vh" }}
          container
          spacing={0}
        >
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <SelectDealer />
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      )}
      {selectFilterDealer !== "Select a Dealer" && selectFilterDealer && (
        <div sx={{ flexGrow: 1 }}>
          <Grid container>
            <Invoices
              isValid={isValid}
              isLoading={invoiceIsLoading}
              invoices={invoices}
              handleINVPagination={handleINVPagination}
            />
          </Grid>
        </div>
      )}
    </>
  );
};

View.proptTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectFields: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  invoiceIsLoading: PropTypes.bool.isRequired,
  handleINVPagination: PropTypes.func.isRequired,
};
