import { TextField, Grid } from "@mui/material/";
import { theme } from "../../utils/theme";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
export const SearchField = ({ value, onChange }) => {
  return (
    <Grid container>
      <TextField
        placeholder="Search…"
        value={value}
        onChange={onChange}
        onKeyPress={(event) => {
          if (event.key === "Enter")
            onChange({
              target: {
                value,
              },
            });
        }}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          "aria-label": "search",
        }}
      />
    </Grid>
  );
};

SearchField.prototype = {
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  width: PropTypes.string,
};
