import {
  GET_INVOICE_BY_DEALER_AND_STATUS_START,
  INVOICE_IS_LOADING,
  GET_INVOICES_PENDING_BY_DEALER_START,
  RESET_INVOICES_LIST,
  PAYING_INVOICE_BY_NUMBER_START,
  GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_START,
  GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_START,
  SET_INVOICE_NUMBER_DASHBOARD_START,
} from "../actionType";

export const invoiceListByDealerAndStatusAction = (
  idDealer,
  statusInvoice,
  pageNumber,
  size
) => ({
  type: GET_INVOICE_BY_DEALER_AND_STATUS_START,
  pageNumber,
  size,
  idDealer,
  statusInvoice,
});

export const invoiceListByDealerAndStatusAllAction = (
  idDealer,
  statusInvoice
) => ({
  type: GET_INVOICES_BY_DEALER_AND_STATUS_ALL_PDF_START,
  idDealer,
  statusInvoice,
});

export const invoicePaidListByDealerAndStatusAndDateAction = (
  idDealer,
  statusInvoice,
  startDate,
  endDate,
  pageNumber,
  size
) => ({
  type: GET_INVOICE_PAID_BY_DEALER_AND_STATUS_AND_DATE_START,
  pageNumber,
  size,
  idDealer,
  statusInvoice,
  startDate,
  endDate,
});

export const invoiceIsLoadingAction = (isLoading) => ({
  type: INVOICE_IS_LOADING,
  isLoading,
});

export const resetInvoicesList = () => ({
  type: RESET_INVOICES_LIST,
});

export const invoicePendingListByDealer = (
  idDealer,
  statusInvoice,
  pageNumber,
  size
) => ({
  type: GET_INVOICES_PENDING_BY_DEALER_START,
  pageNumber,
  size,
  idDealer,
  statusInvoice,
});
export const PayingInvoiceByNumberAction = (idDealer, noInvoice) => ({
  type: PAYING_INVOICE_BY_NUMBER_START,
  idDealer,
  noInvoice,
});

export const generateInvoiceNumberAction = (idDealer) => ({
  type: SET_INVOICE_NUMBER_DASHBOARD_START,
  idDealer,
});
