import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Box } from "@mui/material";

const PREFIX = "Pagination";
const classes = {
  paper: `${PREFIX}-paper`,
  pagination: `${PREFIX}-pagination`,
  selected: `${PREFIX}-selected`,
};

const Root = styled(Box)(({ theme }) => ({
  [`& .${classes.pagination}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0 0",
  },
  [`& .${classes.selected}`]: {
    backgroundColor: theme.palette.red + "!important",
    color: theme.palette.common.white,
  },
}));

export const PaginationComp = ({ count, onChange }) => {
  return (
    <Root
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px 0 0",
      }}
    >
      <Pagination
        count={count}
        onChange={onChange}
        renderItem={(item) => (
          <PaginationItem {...item} classes={{ selected: classes.selected }} />
        )}
      />
    </Root>
  );
};

PaginationComp.propTypes = {
  count: PropTypes.number,
  handlePagination: PropTypes.func,
};
