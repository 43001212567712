import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as Logout } from "../../../assets/images/icon_logout.svg";
import { ReactComponent as LogoIcon } from "../../../assets/images/new_logo_white.svg";
import { ReactComponent as homeIcon } from "../../../assets/images/dashboard_icon.svg";
import { ReactComponent as tasksIcon } from "../../../assets/images/icon_taks.svg";
import { ReactComponent as pendingInvoiceIcon } from "../../../assets/images/icon_pending_invoices.svg";
import { ReactComponent as paidInvoiceIcon } from "../../../assets/images/icon_paid_invoice.svg";
import { ReactComponent as usersIcon } from "../../../assets/images/icon_users.svg";
import { Navbar } from "./list";

const drawerWidth = 250;

const PREFIX = "MyDrawer";
const classes = {
  toolbar: `${PREFIX}-toolbar`,
  iconColor: `${PREFIX}-iconColor`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
};
const Root = styled(Drawer)(({ theme }) => ({
  [`&.${classes.toolbar}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.white,
  },
  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },
  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
}));

export const DrawerSideBar = ({ open, handleDrawerClose, handleLogout }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const navItems = [
    { name: "DASHBOARD", icon: homeIcon, to: "/dashboard" },
    { name: "TASKS", icon: tasksIcon, to: "/task" },
    {
      name: "PENDING INVOICES",
      icon: pendingInvoiceIcon,
      to: "/pending_invoices",
    },
    { name: "PAID INVOICES", icon: paidInvoiceIcon, to: "/paid_invoices" },
    { name: "USERS", icon: usersIcon, to: "/users" },
  ];

  const navItemsBottom = [
    {
      name: "LOGOUT",
      icon: Logout,
      onClick: handleLogout,
    },
  ];

  return (
    <Root
      sx={{ width: drawerWidth, flexShrink: 0 }}
      variant={matches ? "persistent" : "temporary"}
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div className={clsx(classes.drawerHeader, classes.toolbar)}>
        <LogoIcon />

        {!matches && (
          <IconButton onClick={handleDrawerClose}>
            {matches || theme.direction === "ltr" ? (
              <ChevronLeftIcon className={classes.iconColor} />
            ) : (
              <ChevronRightIcon className={classes.iconColor} />
            )}
          </IconButton>
        )}
      </div>

      <Divider
        variant="middle"
        sx={{
          borderBottomWidth: "1px",
          background: "white",
        }}
      />

      <Navbar listItem={navItems} />
      <Navbar listItem={navItemsBottom} marginTop="auto" marginBottom="20%" />
    </Root>
  );
};

DrawerSideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};
