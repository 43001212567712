import { get } from "lodash";
import { GET_DEALERS_COMPLETE, DEALER_IS_LOADING } from "../actionType";

const initialState = {
  dealers: [],

  isLoading: false,
};

export const dealerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEALERS_COMPLETE:
      return {
        ...state,
        dealers: get(action, "result.data"),
      };

    case DEALER_IS_LOADING:
      return {
        ...state,
        isLoading: get(action, "isLoading"),
      };

    default:
      return state;
  }
};
