import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/";
import PropTypes from "prop-types";

const PREFIX = "MyNoData";
const classes = {
  root: `${PREFIX}-root`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
  },
}));

export const NoData = ({ data, isLoading }) => {
  if (data || isLoading) return false;

  return (
    <Root className={classes.root}>
      <Typography variant="h5" component="h2" gutterBottom>
        NOT_ENOUGHT_DATA_FOUND
      </Typography>
    </Root>
  );
};

NoData.propTypes = {
  data: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
