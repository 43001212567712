import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { searchAction } from "../../Redux/actions/searchAction";
import {
  isTaskModalAction,
  selectedTaskAction,
  taskForDropdownAction,
  taskIsLoadingAction,
  tasksAction,
} from "../../Redux/actions/taskAction";
import { SELECTED_TASK_COMPLETE } from "../../Redux/actionType";

import {
  isLoadingSelector,
  isTaskModalSelector,
  selectedTaskSelector,
  taskForDropdownSelector,
  taskIsLoadingSelector,
  tasksSelector,
} from "../../Redux/selector/";
import { TASK_SEARCH } from "../../utils/urls";
import { GET_TASKS_COMPLETE } from "../../Redux/actionType";

import { View } from "./view";

export const Task = () => {
  const dispatch = useDispatch();

  const selectedTasks = useSelector((state) => selectedTaskSelector(state));
  const tasks = useSelector((state) => tasksSelector(state));
  const taskForDropdown = useSelector((state) =>
    taskForDropdownSelector(state)
  );
  const isLoading = useSelector((state) => isLoadingSelector(state));

  const taskIsLoading = useSelector((state) => taskIsLoadingSelector(state));
  const isTaskModal = useSelector((state) => isTaskModalSelector(state));

  const [selectedSingleTask, setSelectedSingleTask] = useState(null);
  const [selectedSinglePackage, setSelectedSinglePackage] = useState(null);

  const [searchTSKValue, setSearchTSKValue] = useState("");

  /**
   * Pagination
   */

  useEffect(() => {
    dispatch(tasksAction(1, 10));
    dispatch(taskForDropdownAction());
  }, []);

  const handleModalTaskOpen = (task) => {
    if (!task) return;
    dispatch(isTaskModalAction({ modal: "ShowTasks" }));
    dispatch(selectedTaskAction(task));
  };

  const handleModalClose = () => {
    setSelectedSinglePackage(null);
    setSelectedSingleTask(null);
    dispatch(isTaskModalAction(false));
    dispatch({ type: SELECTED_TASK_COMPLETE, result: [] });
  };

  const openModalAddTask = () => {
    setSelectedSingleTask(null);
    dispatch(isTaskModalAction({ modal: "AddNewTask" }));
  };

  const handleAddNewPackage = () => {
    dispatch(isTaskModalAction({ modal: "AddPackage" }));
  };

  const handleTSKPagination = (event, value) => {
    if (searchTSKValue) {
      dispatch(
        searchAction(TASK_SEARCH, searchTSKValue, value, GET_TASKS_COMPLETE)
      );
    } else {
      dispatch(tasksAction(value, 10));
    }
  };

  const handleTSKSearch = (e) => {
    const query = e.target.value;
    setSearchTSKValue(query);
    dispatch(taskIsLoadingAction(true));
    if (!query) {
      dispatch(tasksAction(1, 10));
      return;
    }
    dispatch(searchAction(TASK_SEARCH, query, 1, GET_TASKS_COMPLETE));
  };

  return (
    <View
      handleModalTaskOpen={handleModalTaskOpen}
      handleModalClose={handleModalClose}
      openModalAddTask={openModalAddTask}
      isTaskModal={isTaskModal}
      selectedTasks={selectedTasks}
      isLoading={isLoading}
      taskIsLoading={taskIsLoading}
      tasks={tasks}
      taskForDropdown={taskForDropdown}
      selectedSingleTask={selectedSingleTask}
      setSelectedSingleTask={setSelectedSingleTask}
      selectedSinglePackage={selectedSinglePackage}
      setSelectedSinglePackage={setSelectedSinglePackage}
      handleAddNewPackage={handleAddNewPackage}
      handleTSKPagination={handleTSKPagination}
      handleTSKSearch={handleTSKSearch}
      searchTSKValue={searchTSKValue}
    />
  );
};
