import React, { useEffect } from "react";
import clsx from "clsx";
import { styled } from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch, useSelector } from "react-redux";
import { userInfoSelector } from "../../Redux/selector";
import { userInfoAction, userResetInfo } from "../../Redux/actions/userAction";
import { logoutAction } from "../../Redux/actions/loginActions";
import { DrawerSideBar } from "./components/Drawer";
import { Outlet } from "react-router-dom";
import { tokenSelector } from "../../Redux/selector/";
import { useNavigate } from "react-router-dom";
const drawerWidth = 240;

const PREFIX = "MyMain";
const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  toolbar: `${PREFIX}-toolbar`,
  iconColor: `${PREFIX}-iconColor`,
  name: `${PREFIX}-name`,
  account: `${PREFIX}-account`,
  profileImg: `${PREFIX}-profileImg`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  grow: `${PREFIX}-grow`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  sectionMobile: `${PREFIX}-sectionMobile`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  drawerHeader: `${PREFIX}-drawerHeader`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
  [`& .${classes.appBar}`]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${classes.appBarShift}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  [`& .${classes.toolbar}`]: {
    backgroundColor: theme.palette.white,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.name}`]: {
    marginLeft: "10px",
    color: theme.palette.primary.main,
  },
  [`& .${classes.account}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  [`& .${classes.profileImg}`]: {
    width: 30,
    height: 30,
    marginLeft: theme.spacing(3),
  },
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
  [`& .${classes.hide}`]: {
    display: "none",
  },
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
  [`& .${classes.sectionDesktop}`]: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  [`& .${classes.sectionMobile}`]: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: theme.palette.primary.main,
  },
  [`& .${classes.content}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.bgColor,
    minHeight: "100vh",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    /** less than 1280px, we have 0px margin */
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0",
    },
  },
  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "0px",
  },
  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
}));

export const Main = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => userInfoSelector(state));
  const token = useSelector((state) => tokenSelector(state));
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (!userInfo?.email) {
      dispatch(userInfoAction());
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  useEffect(() => {
    matches && setOpen(true);
  }, [matches]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(userResetInfo());
    dispatch(logoutAction());
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton color="inherit">
          <Badge
            sx={{
              background: theme.palette.primary.main,
              color: theme.palette.common.white,
            }}
          >
            <ExitToAppIcon />
          </Badge>
        </IconButton>
        <p>Log Out</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div className={classes.account} onClick={handleProfileMenuOpen}>
              <Avatar className={classes.profileImg} src="" />
              <Typography className={classes.name}>
                {userInfo?.first_name} {userInfo?.last_name}
              </Typography>
              <ArrowDropDownIcon
                aria-controls={menuId}
                sx={{
                  cursor: "pointer",
                  color: (theme) => theme.palette.primary.main,
                }}
              />
            </div>
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon className={classes.iconColor} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}

      <DrawerSideBar
        handleDrawerClose={handleDrawerClose}
        open={open}
        handleLogout={handleLogout}
      />

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        <Outlet />
      </main>
    </Root>
  );
};
