import { all } from "redux-saga/effects";
import authUser from "./loginSaga";
import userInfo from "./userSaga";
import localToken from "./localSaga";
import searchSaga from "./searchSaga";
import taskSaga from "./taskSaga";
import dealerSaga from "./dealerSaga";
import invoiceSaga from "./invoiceSaga";
import userList from "./userListSaga";
import forgotPasswordSaga from "./forgotPasswordSaga";
export default function* rootSaga() {
  yield all([
    localToken(),
    authUser(),
    userInfo(),
    taskSaga(),
    searchSaga(),
    dealerSaga(),
    invoiceSaga(),
    userList(),
    forgotPasswordSaga(),
  ]);
}
