import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { theme } from "../../utils/theme";

export const Input = ({ fields, selectWidth, width }) => {
  switch (fields.elementType) {
    case "input":
      return (
        <TextField
          sx={{
            boxSizing: "border-box",
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
            "& > *": {
              width: width || "270px",
            },
          }}
          variant="outlined"
          type="text"
          id="standard-basic"
          label={fields.placeholder}
          placeholder={fields.placeholder}
          inputRef={fields.ref}
          error={fields.error}
          defaultValue={fields.defaultValue}
          helperText={fields.helperText}
          InputProps={fields?.inputProps}
        />
      );

    case "phone":
      return (
        <TextField
          sx={{
            boxSizing: "border-box",
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
            "& > *": {
              width: width || "270px",
            },
          }}
          variant="outlined"
          type="text"
          id="standard-basic"
          label={fields.placeholder}
          placeholder={fields.placeholder}
          inputRef={fields.ref}
          error={fields.error}
          defaultValue={fields.defaultValue}
          helperText={fields.helperText}
        />
      );

    case "email":
      return (
        <TextField
          sx={{
            boxSizing: "border-box",
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
            "& > *": {
              width: width || "270px",
            },
          }}
          variant="outlined"
          type="email"
          id="standard-basic"
          label={fields.placeholder}
          placeholder={fields.placeholder}
          inputRef={fields.ref}
          error={fields.error}
          defaultValue={fields.defaultValue}
          disabled={fields.disable}
          helperText={fields.helperText}
        />
      );

    case "password":
      return (
        <TextField
          sx={{
            boxSizing: "border-box",
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
            "& > *": {
              width: width || "270px",
            },
          }}
          variant="outlined"
          type="password"
          id="standard-basic"
          label={fields.placeholder}
          placeholder={fields.placeholder}
          inputRef={fields.ref}
          error={fields.error}
          helperText={fields.helperText}
          InputProps={fields.inputProps}
        />
      );

    case "select":
      return (
        <FormControl
          variant="outlined"
          sx={{
            width: selectWidth || "70.8ch",
            marginTop: theme.spacing(1),
          }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            {fields.label}
          </InputLabel>
          <Select
            defaultValue=""
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={fields.value || ""}
            onChange={fields.handler}
            label={fields.label}
            error={fields.error}
          >
            {fields?.list?.map((item) => (
              <MenuItem key={item.name ? item.name : item} value={item}>
                {item.name ? item.name : item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "multiline":
      return (
        <TextField
          sx={{
            boxSizing: "border-box",
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
            "& > *": {
              width: width || "270px",
            },
          }}
          variant="outlined"
          type="input"
          id="standard-basic"
          label={fields.placeholder}
          placeholder={fields.placeholder}
          inputRef={fields.ref}
          error={fields.error}
          multiline={true}
          rows={4}
          maxRows={4}
          helperText={fields.helperText}
          fullWidth
        />
      );
    default:
      return;
  }
};

Input.propTypes = {
  fields: PropTypes.object.isRequired,
  selectWidth: PropTypes.string,
  width: PropTypes.string,
  helperText: PropTypes.string,
};
