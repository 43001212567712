import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Divider, Typography, Box, Grid, Chip } from "@mui/material/";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Helmet } from "react-helmet";
import { styled } from "@mui/material/styles";
import { Modal, Loading, Button, Input } from "../../components";
import { Tasks } from "./components/Tasks/Tasks";
import { theme } from "../../utils/theme";
import {
  createTaskAction,
  isTaskModalAction,
  editTaskAction,
  taskIsLoadingAction,
} from "../../Redux/actions/taskAction";

export const View = ({
  tasks,
  handleModalClose,
  openModalAddTask,
  isTaskModal,
  selectedTasks,
  packageIsLoading,
  taskIsLoading,
  selectedSingleTask,
  setSelectedSingleTask,
  handleTSKPagination,
  handleTSKSearch,
  searchTSKValue,
}) => {
  const dispatch = useDispatch();

  // For Task
  const taskRef = useRef(null);
  const priceRef = useRef(null);
  const descriptionRef = useRef(null);

  const [taskError, setTaskError] = useState(false);
  const [priceError, setPriceError] = useState();
  const [descriptionError, setDescriptionError] = useState();

  const cleanUp = () => {
    handleModalClose();
  };

  const tasksInputFields = {
    taskName: {
      elementType: "input",
      placeholder: "Task Name",
      ref: taskRef,
      error: taskError,
      defaultValue: selectedSingleTask?.name,
    },
    price: {
      elementType: "input",
      placeholder: "Price",
      ref: priceRef,
      error: priceError,
      defaultValue: selectedSingleTask?.cost,
    },
    description: {
      elementType: "input",
      placeholder: "Description",
      ref: descriptionRef,
      error: descriptionError,
      defaultValue: selectedSingleTask?.description,
    },
  };

  const handleSubmit = (e, _id) => {
    e.preventDefault();

    const task = taskRef?.current?.value || setTaskError(true);
    const price = priceRef?.current?.value || setPriceError(true);
    const description =
      descriptionRef?.current?.value || setDescriptionError(true);

    task && setTaskError(false);
    price && setPriceError(false);
    description && setDescriptionError(false);

    if (!task || !price || !description) return false;

    dispatch(taskIsLoadingAction(true));

    /** if the form is updateTask */
    if (isTaskModal?.modal === "UpdateTask") {
      dispatch(
        editTaskAction({
          _id: selectedSingleTask._id,
          name: task,
          description: description,
          cost: price,
        })
      );
      return;
    }

    dispatch(
      createTaskAction({
        name: task,
        description: description,
        cost: price,
      })
    );
  };

  const handleUpdateTask = (singleTask) => {
    setSelectedSingleTask(singleTask);
    dispatch(isTaskModalAction({ modal: "UpdateTask" }));
  };

  /** Modal For Create Task/package, edit */
  let modalBody;

  if (
    isTaskModal?.modal === "AddNewTask" ||
    isTaskModal?.modal === "UpdateTask"
  )
    modalBody = (
      <Box sx={{ marginTop: theme.spacing(2) }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container>
            {Object.keys(tasksInputFields).map((key, i, array) => (
              <Grid item xs={12} key={key}>
                <Input fields={tasksInputFields[key]} width="360px" />
              </Grid>
            ))}
          </Grid>

          <Button
            colorButton="buttons"
            title={isTaskModal?.modal === "AddNewTask" ? "Add" : "save"}
            width="100%"
            marginBottom="0"
            marginTop="30px"
            isLoading={taskIsLoading}
          />
        </form>
      </Box>
    );

  return (
    <div>
      <Helmet title="Tasks - Track Detailing" />
      <Modal open={!!isTaskModal?.modal} handleClose={cleanUp}>
        <Box sx={{ width: 400, padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ fontWeight: 800 }}>
              {isTaskModal?.modal === "AddNewTask" && "Add New Task"}
              {isTaskModal?.modal === "UpdateTask" && "Edit Task"}
              {isTaskModal?.modal === "ShowTasks"}
            </Typography>
            <CloseIcon
              sx={{
                backgroundColor: theme.palette.buttons.main,
                borderRadius: 20,
                color: theme.palette.common.white,
                cursor: "pointer",
              }}
              onClick={handleModalClose}
            />
          </Box>

          {modalBody}
        </Box>
      </Modal>

      <div sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Tasks
            isLoading={taskIsLoading}
            tasks={tasks}
            openModalAddTask={openModalAddTask}
            handleUpdateTask={handleUpdateTask}
            handleTSKPagination={handleTSKPagination}
            handleSearch={handleTSKSearch}
            searchValue={searchTSKValue}
          />
        </Grid>
      </div>
    </div>
  );
};

View.proTypes = {
  taskForDropdown: PropTypes.array.isRequired,
  isTaskModal: PropTypes.any,
  handleModalTaskOpen: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  selectedTasks: PropTypes.object.isRequired,
  taskIsLoading: PropTypes.bool.isRequired,
  selectedSingleTask: PropTypes.any,
  setSelectedSingleTask: PropTypes.any,
  selectedSinglePackage: PropTypes.any,
  setSelectedSinglePackage: PropTypes.any,
  handleTSKPagination: PropTypes.func.isRequired,
  handleTSKSearch: PropTypes.func.isRequired,
  searchTSKValue: PropTypes.string.isRequired,
};
