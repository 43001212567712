import { Box } from "@mui/material";
import { theme } from "../../utils/theme";
import { TraceSpinner } from "react-spinners-kit";
import PropTypes from "prop-types";

export const Loading = ({ isLoading, width, height }) => {
  if (!isLoading) return false;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: width || "100%",
        height: height || "300px",
      }}
    >
      <TraceSpinner
        size={30}
        frontColor={theme.palette.red}
        loading={isLoading}
      />
    </Box>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};
