import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { theme } from "../../../../utils/theme";
import { userStatusAction } from "../../../../Redux/actions/userListActions";
import { SelectList } from "../../../../components/";

export const TableUsers = ({ users, handleEditUser, isLoading }) => {
  const dispatch = useDispatch();

  if (isLoading || !users?.length) return false;

  const selectData = ["Pending", "Active", "Rejected", "Deactivated"];

  const handleOnChange = (id, e) => {
    dispatch(userStatusAction(id, e.target.value));
  };

  return (
    <TableContainer
      sx={{
        marginTop: theme.spacing(2),
        overflowX: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "67vh",
      }}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            "& th": {
              color: theme.palette.white,
              backgroundColor: theme.palette.primary.main,
              fontWeight: "bold",
            },
          }}
        >
          <TableRow>
            <TableCell sx={{ fontWeight: 600 }}>First Name</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="left">
              Last Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="left">
              Phone Number
            </TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="left">
              E-mail
            </TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="left">
              User Status
            </TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">
              Draw
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user._id}
              hover
              sx={{
                "&": {
                  padding: "10px",
                },
              }}
            >
              <TableCell component="th" scope="row">
                {user?.first_name}
              </TableCell>
              <TableCell
                sx={{
                  "&": {
                    padding: "10px",
                  },
                }}
                align="left"
              >
                {user?.last_name}
              </TableCell>
              <TableCell
                sx={{
                  "&": {
                    padding: "10px",
                  },
                }}
                align="left"
              >
                {user?.phone_number}
              </TableCell>
              <TableCell
                sx={{
                  "&": {
                    padding: "10px",
                  },
                }}
                align="left"
              >
                {user?.email}
              </TableCell>
              <TableCell
                sx={{
                  "&": {
                    padding: "10px",
                  },
                }}
                align="left"
              >
                <SelectList
                  data={selectData}
                  selectedItem={user?.status}
                  handleOnChange={(e) => handleOnChange(user?._id, e)}
                  colors={{
                    green: "Active",
                    yellow: "Pending",
                    red: "Rejected",
                    normal: "Deactivated",
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  "&": {
                    padding: "10px",
                  },
                }}
                align="right"
              >
                <EditIcon
                  onClick={handleEditUser.bind(this, user)}
                  sx={{ cursor: "pointer" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableUsers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  handleEditUser: PropTypes.func.isRequired,
};
