import { InputBase, Box, fade, Grid, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { theme } from "../../utils/theme";
import { Button } from "../../components/Button/Button";
import { SearchField } from "../SearchField/searchField";

// Styling
export const Filter = ({
  title,
  btnTitle,
  openModalHandler,
  btnWidth,
  btnColor,
  onChange,
  value,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography fontWeight="bold" variant="h6" gutterBottom>
            {title || ""}
          </Typography>
        </Box>
        <Box sx={{ m: 0.5 }}>
          <Button
            colorButton={btnColor}
            title={btnTitle}
            width={btnWidth}
            marginBottom="10px"
            onClick={openModalHandler}
            icon={<AddIcon sx={{ marginRight: theme.spacing(1) }} />}
          ></Button>
        </Box>
        <Box sx={{ m: 2 }}>
          <SearchField onChange={onChange} value={value} />
        </Box>
      </Box>
    </div>
  );
};

Filter.propTypes = {
  btnWidth: PropTypes.string,
  btnTitle: PropTypes.string.isRequired,
  openModalHandler: PropTypes.func.isRequired,
  btnColor: PropTypes.string,
};
