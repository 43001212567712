import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";

export const DatePickerComponent = ({ value, label, setValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          margin: "7px",
          padding: "0.5px",
          "& > .MuiFormLabel-root": {
            color: "red",
          },
        }}
        label={label}
        value={value || null}
        onChange={setValue}
      />
    </LocalizationProvider>
  );
};

DatePickerComponent.defaultProps = {
  inputVariant: "outlined",
  format: "MM/DD/YYYY",
};

DatePickerComponent.propTypes = {
  disableFuture: PropTypes.bool,
  clearable: PropTypes.bool,
  openTo: PropTypes.string,
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  setValue: PropTypes.func,
};
