import { useRef, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { theme } from "../../../../utils/theme";
import { Modal } from "../../../../components";
import { ReactComponent as PaymentConfirmationIcon } from "../../../../assets/images/payment_confirmation.svg";
// Styling
import Button from "@mui/material/Button";

export const PayInvoiceModal = ({
  isModal,
  handleClose,
  isLoading,
  isLoadingAction,
  Infopay,
  handlePayInvoice,
}) => {
  return (
    <Dialog
      open={isModal}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
      sx={{
        padding: theme.spacing(2),
        position: "absolute",
        top: theme.spacing(5),
        bottom: theme.spacing(5),
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <IconButton disableRipple sx={{}}>
          <PaymentConfirmationIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography color="red" variant="h3">
          Please Confirm
        </Typography>
        <Typography variant="h6">Are you sure you want to pay?</Typography>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "space-around", paddingBottom: "20px" }}
      >
        <Button
          sx={{ width: "140px", color: "black" }}
          variant="outlined"
          color="warning"
          onClick={handleClose}
        >
          NO
        </Button>
        <Button
          sx={{ width: "140px", color: "white" }}
          variant="contained"
          color="buttons"
          onClick={handlePayInvoice}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PayInvoiceModal.prototype = {
  isModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingAction: PropTypes.func.isRequired,
  InfoEmail: PropTypes.object,
};
