import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDoneAction } from "../../Redux/actions/utilityActions";
import {
  isLoadingSelector,
  dealersSelector,
  invoicesSelector,
  invoicesIsLoadingSelector,
  invoicesPDFListSelector,
  isDoneSelector,
} from "../../Redux/selector/";
import { View } from "./view";
import { getDealersListAction } from "../../Redux/actions/dealerAction";
import {
  invoiceListByDealerAndStatusAction,
  resetInvoicesList,
  invoiceListByDealerAndStatusAllAction,
  generateInvoiceNumberAction,
} from "../../Redux/actions/invoiceAction";
import { downloadInvoice } from "../../utils/generaluFunctions";
export const Dashboard = () => {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => invoicesSelector(state));
  const isLoading = useSelector((state) => isLoadingSelector(state));
  const isDone = useSelector((state) => isDoneSelector(state));
  const dealerList = useSelector((state) => dealersSelector(state));
  const invoicesIsLoading = useSelector((state) =>
    invoicesIsLoadingSelector(state)
  );
  const selectDataDealer = dealerList?.items?.map((items) => ({
    value: items?._id,
    text: items?.name,
  }));
  const firstOption = { value: "Select a Dealer", text: "Select a Dealer" };
  selectDataDealer?.unshift(firstOption);
  const [selectFilterDealer, setSelectFilterDealer] = useState();
  const [vehicles, setVehicles] = useState([]);
  const selectDataByOptions = [
    { value: "WIP", text: "WIP" },
    { value: "APPROVED", text: "APPROVED" },
  ];
  const [selectFilterByOptions, setSelectFilterByOptions] = useState(
    selectDataByOptions?.[0].value
  );
  let arrayVehicles = [];
  const handleINVPagination = (event, value) => {
    //arrayVehicles = [];
    //setVehicles([]);
    dispatch(
      invoiceListByDealerAndStatusAction(
        selectFilterDealer,
        selectFilterByOptions,
        value,
        10
      )
    );
  };

  const handlePdfInvoiceApproved = () => {
    dispatch(generateInvoiceNumberAction(selectFilterDealer));
  };

  const selectFields = {
    dealer: {
      label: "Filter By Dealer",
      name: "dealer",
      value: selectFilterDealer,
      setCustomObjectData: setSelectFilterDealer,
      options: selectDataDealer,
      width: "320px",
    },
    status: {
      label: "Filter By",
      name: "status",
      value: selectFilterByOptions,
      setCustomObjectData: setSelectFilterByOptions,
      options: selectDataByOptions,
      width: "200px",
    },
  };

  useEffect(() => {
    if (selectFilterDealer !== firstOption.value && selectFilterDealer) {
      arrayVehicles = [];
      setVehicles([]);
      dispatch(resetInvoicesList());
      dispatch(
        invoiceListByDealerAndStatusAction(
          selectFilterDealer,
          selectFilterByOptions,
          1,
          10
        )
      );

      dispatch(
        invoiceListByDealerAndStatusAllAction(
          selectFilterDealer,
          selectFilterByOptions
        )
      );
    }
  }, [selectFilterDealer, selectFilterByOptions]);

  useEffect(() => {
    arrayVehicles = [];
    setVehicles([]);
    dispatch(resetInvoicesList());
    dispatch(getDealersListAction());
  }, []);

  const invoicesData = useSelector((state) => invoicesPDFListSelector(state));

  useEffect(() => {
    if (isDone && invoicesData) {
      downloadInvoice(invoicesData, false, true);
      dispatch(isDoneAction(false));
      dispatch(resetInvoicesList());
    }
  }, [isDone, invoicesData]);

  useEffect(() => {
    if (invoices?.length > 0) {
      invoices?.forEach((invoice) =>
        invoice?.vehicles?.forEach((vehicle) => arrayVehicles.push(vehicle))
      );

      arrayVehicles.forEach((vehicle) => {
        vehicle.description = "";
      });

      arrayVehicles.forEach((vehicle) =>
        vehicle.tasks.forEach((task, index, arr) => {
          vehicle.description +=
            index !== 0 ? "," + task?.name : "" + task?.name;
        })
      );
    }
  }, [invoices]);

  useEffect(() => {
    if (arrayVehicles?.length > 0) {
      setVehicles(arrayVehicles);
    }
  }, [arrayVehicles]);

  useEffect(() => {
    if (dealerList) {
      if (selectDataDealer?.length > 0) {
        setSelectFilterDealer(selectDataDealer?.[3]?.value);
      } else {
        setSelectFilterDealer(selectDataDealer?.[0]?.value);
      }
    }
  }, [dealerList]);
  return (
    <View
      isLoading={isLoading}
      selectFields={selectFields}
      selectFilterDealer={selectFilterDealer}
      invoiceIsLoading={invoicesIsLoading}
      invoices={invoices}
      handleINVPagination={handleINVPagination}
      selectFilterByOptions={selectFilterByOptions}
      handlePdfInvoiceApproved={handlePdfInvoiceApproved}
    />
  );
};
