import { get } from "lodash";

import { USER_INFO_COMPLETE, RESET_INFO_USER } from "../actionType";

const initialState = {
  email: null,
  id: null,
  first_name: null,
  last_name: null,
  organization: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO_COMPLETE:
      return {
        ...state,
        id: get(action, "result.data.id"),
        email: get(action, "result.data.email"),
        first_name: get(action, "result.data.first_name"),
        last_name: get(action, "result.data.last_name"),
        organization: get(action, "result.data.organization"),
      };
    case RESET_INFO_USER:
      return {
        email: null,
        id: null,
        first_name: null,
        last_name: null,
        organization: null,
      };

    default:
      return state;
  }
};
