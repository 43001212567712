import { View } from "./view";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loginAction } from "../../Redux/actions/loginActions";
import { isLoadingAction } from "../../Redux/actions/utilityActions";
import { tokenSelector } from "../../Redux/selector/";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

export const SignInIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => tokenSelector(state));
  const emailRef = useRef(null);
  const [emailError, setEmailError] = useState(false);
  const passwordRef = useRef(null);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputFields = {
    email: {
      type: "text",
      label: "Email or Username",
      ref: emailRef,
      error: emailError,
      placeholder: "example@email.com",
      inputProps: {
        shrink: true,
      },
    },
    password: {
      label: "Password",
      ref: passwordRef,
      error: passwordError,
      placeholder: "Password",
      type: showPassword ? "text" : "password",
      inputProps: {
        shrink: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    // form validation;
    if (!email) setEmailError(true);
    if (!password) setPasswordError(true);

    emailError && email && setEmailError(false);
    passwordError && password && setPasswordError(false);

    if (!password || !email) return;

    dispatch(isLoadingAction(true));
    dispatch(
      loginAction({
        username: email.replace(/\s+/g, ""),
        password,
        organization: "TrackDetailing",
        type_login: "from_dashboard",
      })
    );
  };
  return <View handleSubmit={handleSubmit} inputFields={inputFields} />;
};
