import { get } from "lodash";

/** Utility Reducer */
export const snackbarSelector = (state) =>
  get(state, "utilityReducer.snackBar");
export const isLoadingSelector = (state) =>
  get(state, "utilityReducer.isLoading");
export const isDoneSelector = (state) => get(state, "utilityReducer.isDone");
export const isLoadingModalSelector = (state) =>
  get(state, "utilityReducer.isLoadingModal");
export const isModalCreateUserSelector = (state) =>
  get(state, "utilityReducer.isModalCreateUser");
export const isModalEditUserSelector = (state) =>
  get(state, "utilityReducer.isModalEditUser");
export const isModalSendInvoiceSelector = (state) =>
  get(state, "utilityReducer.isModalSendInvoice");
/** Login */
export const tokenSelector = (state) => get(state, "loginReducer.access_token");
/** User Info */
export const userInfoSelector = (state) => get(state, "userReducer");
/** Dashboard */
export const dashboardInfoSelector = (state) =>
  get(state, "dashboardRedux.dashboardInfo");

/** Tasks */
export const tasksSelector = (state) => get(state, "taskReducer.tasks");
export const selectedTaskSelector = (state) =>
  get(state, "taskReducer.selectedTasks");
export const isTaskModalSelector = (state) =>
  get(state, "taskReducer.isTaskModal");
export const taskForDropdownSelector = (state) =>
  get(state, "taskReducer.taskForDropDown");
export const taskIsLoadingSelector = (state) =>
  get(state, "taskReducer.isLoading");

/** Dealers */
export const dealersSelector = (state) => get(state, "dealerReducer.dealers");

/**Invoices */

export const invoicesSelector = (state) =>
  get(state, "invoiceReducer.invoices");
export const invoicesIsLoadingSelector = (state) =>
  get(state, "invoiceReducer.isLoading");
export const invoicesPDFListSelector = (state) =>
  get(state, "invoiceReducer.invoicesPDFList");

/** USER List */
export const userListSelector = (state) =>
  get(state, "userListReducer.userList");
